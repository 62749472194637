import { Row, Input, Button, Table, Grid, Space, Tooltip } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FolderAddOutlined,
  HolderOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./documents.less";
import EditDocuments from "./EditDocuments/EditDocuments";
import AddNewFolder from "./AddNewFolder/AddNewFolder";
import AddNewDocument from "./AddNewDocument/AddNewDocument";
import DeleteDocument from "./DeleteDocument/DeleteDocument";
import BulkEdit from "./BulkEdit/BulkEdit";
import { listItemsTypesSearch, listProjectsSearch } from "utils/RESTApi";
import { useSelector } from "react-redux";
import {
  getCurrentIdToken,
  getCurrentOrganization,
  getOrganizationId,
} from "store/slices/loginSlice";
import { CommonError } from "utils/Helper/CommonError";
import {
  createTree,
  createTreeDataStructure,
  fetchData,
} from "./commonFunction";
import {
  documentAttachmentsByDocumentId,
  documentsByOrganizationIDCustom,
  searchDocumentsCustom,
  updateDocuments,
} from "utils/Actions";
import SingleEdit from "./SingleEdit/SingleEdit";
import {
  dateTimeConversionBasedOnTimeZone,
  debounceFn,
  fetchFoldersFromS3,
  moveFileBetweenFoldersInS3,
} from "utils/Helper/commonMethods";
import { useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SearchableTableWithSorting from "Common/Components/DataTable/DataTableWithSorting";
import { displayTimeFormat } from "utils/Constants";
import { getModuleAccess } from "store/slices/moduleAccessSlice";
import EllipsisText from "Common/Components/EllipsisText/EllipsisText";

const RowContext = React.createContext({});

export default function Documents() {
  const { Search } = Input;
  const [documentEditModal, setDocumentEditModal] = useState(false);
  const [addNewFolderModal, setAddNewFolderModal] = useState(false);
  const [actionType, setActionType] = useState(0);
  const [openActionModel, setOpenActionModel] = useState(false);
  const [addNewDoc, setAddNewDoc] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [itemTypesList, setItemTypesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState();
  const [documentData, setDocumentData] = useState([]);
  const [nextTokenData, setNextTokenData] = useState(null);
  const [documentId, setDocumentId] = useState("");
  const [editData, setEditData] = useState([]);
  const [folder, setFolder] = useState([]);
  const [documentToEdit, setDocumentToEdit] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [folderArray, setFolderArray] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingScrolls, setLoadingScrolls] = useState(false);
  const [sortTableOrder, setSortTableOrder] = useState("");
  const [sortTableColumn, setSortTableColumn] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [draggedItem, setDraggedItem] = useState(null);
  const [dragOverItem, setDragOverItem] = useState(null);

  const [allFolders, setAllFolders] = useState([]);
  const [multipleDelete, setMultipleDelete] = useState(false);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const insideFolder = searchParams.get("folder");
  const parentFolder = searchParams.get("parent");

  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentIdToken);

  const currentOrganization = useSelector(getCurrentOrganization);

  const { hasReadAccesss, hasCreateAccess, hasUpdateAccess, hasDeleteAccess } =
    useSelector((state) => getModuleAccess(state, "Documents"));

  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{
          cursor: "move",
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const columnData = useMemo(() => {
    const data = documentData?.map((item, index) => {
      return {
        key: index,
        id: item?.id,
        name: item?.name,
        fileType: item?.fileType,
        item: item?.DocumentAttachments?.items?.reduce((acc, curr) => {
          if (curr && curr?.Items !== null) {
            acc += (acc ? ", " : "") + curr?.Items?.serialNumber;
          }
          return acc;
        }, ""),
        itemType: item?.DocumentAttachments?.items?.reduce((acc, curr) => {
          if (curr && curr?.ItemTypes !== null) {
            acc += (acc ? ", " : "") + curr?.ItemTypes?.number;
          }
          return acc;
        }, ""),
        associationDate: item?.DocumentAttachments?.items[0]?.associatedAt,
        created: item?.createdAt,
        filename: item?.fileName,
        type: item?.DocumentAttachments?.items[0]?.type,
      };
    });

    return data;
  }, [documentData, folder]);

  // fetch folder list :: begin
  // const fetchFolders = async (folderName = "Documents") => {
  //   const folders = await fetchFoldersFromS3(folderName);

  //   const uniqueFolders = Array.from(
  //     new Set(
  //       folders.flatMap((folder) => folder.folder.split("/").filter(Boolean))
  //     )
  //   );

  //   setAllFolders(uniqueFolders);
  //   const inputArray = folders?.map((folder) => folder?.folder?.split("/"));
  //   setFolderArray(inputArray);

  //   const result = createTreeDataStructure(inputArray);
  //   setTreeData(result);

  //   const innerFolders = Array.from(
  //     new Set(
  //       folders?.map((folder) => folder?.folder?.split("/")[1]).filter(Boolean)
  //     )
  //   );

  //   if (insideFolder !== null && parentFolder === null) {
  //     const folderArray = folders
  //       ?.map((folder) => folder?.folder?.split("/"))
  //       .filter(Boolean);

  //     const result = [];
  //     const seenItems = new Set();

  //     folderArray.forEach((array) => {
  //       if (array.includes(insideFolder)) {
  //         const thirdItem = array[2];
  //         if (thirdItem && !seenItems.has(thirdItem)) {
  //           const folderMetadata = folders.find(
  //             (f) => f.folder === array.join("/")
  //           );
  //           result.push({
  //             folder: folderMetadata?.folder || thirdItem,
  //             name: folderMetadata?.name || thirdItem,
  //             createdAt: folderMetadata?.createdAt || null,
  //           });
  //           seenItems.add(thirdItem);
  //         }
  //       }
  //     });

  //     setFolder([...result]);
  //   } else if (parentFolder !== null) {
  //     const folderArray = folders
  //       ?.map((folder) => folder.folder?.split("/"))
  //       .filter(Boolean);

  //     const result = [];
  //     const seenItems = new Set();

  //     folderArray.forEach((array) => {
  //       if (array.includes(insideFolder)) {
  //         const thirdItem = array[3];
  //         if (thirdItem && !seenItems.has(thirdItem)) {
  //           const folderMetadata = folders.find(
  //             (f) => f.folder === array.join("/")
  //           );
  //           result.push({
  //             folder: folderMetadata?.folder || thirdItem,
  //             name: folderMetadata?.name || thirdItem,
  //             createdAt: folderMetadata?.createdAt || null,
  //           });
  //           seenItems.add(thirdItem);
  //         }
  //       }
  //     });

  //     setFolder([...result]);
  //   } else {
  //     const result = innerFolders.map((folder) => {
  //       const folderData = folders.find(
  //         (f) => f.folder.split("/")[1] === folder
  //       );
  //       return {
  //         folder: folderData?.folder || folder, // Include full folder path
  //         name: folderData?.name || folder,
  //         createdAt: folderData?.createdAt || null,
  //       };
  //     });

  //     setFolder([...result]);
  //   }
  // };

  const fetchFolders = async () => {
    let filter = {
      organizationID: { eq: userOrganizationId },
      isDeleted: { ne: true },
      fileType: { eq: "Folder" },
    };

    const sort = {};

    const response = await searchDocumentsCustom(
      filter,
      sort,
      9999,
      null,
      currentIdToken
    );

    const tree = createTree(response?.items);
    setTreeData(tree);
  };

  useEffect(() => {
    fetchFolders();
  }, [insideFolder, addNewFolderModal]);
  // fetch folder list :: end

  // fetch dropdown data :: begin
  useEffect(() => {
    fetchData(
      userOrganizationId,
      listProjectsSearch,
      setLoading,
      setProjectList,
      setItemTypesList,
      setNextToken
    );
    fetchData(
      userOrganizationId,
      listItemsTypesSearch,
      setLoading,
      setProjectList,
      setItemTypesList,
      setNextToken
    );
  }, []);

  // useEffect(() => {
  //   setSelectedDocuments([]);
  //   setSelectedKeys([]);
  // }, [columnData]);
  // fetch dropdown data :: end
  const handleSort = async (columnKey, order) => {
    let sortOrder = order === "ascend" ? "asc" : "desc";
    setSortTableColumn(columnKey);
    setSortTableOrder(sortOrder);

    setNextTokenData(null);
    setDocumentData([]);
    await fetchDocuments(allFolders, null, sortOrder, columnKey, searchQuery);
  };

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 20,
      render: (_, value) => {
        if (value.fileType === "Folder") {
          return null;
        } else {
          return <DragHandle />;
        }
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (_, value) => {
        if (value.fileType === "Folder") {
          return (
            <Button
              type="link"
              onClick={() => {
                const folderId = value?.id;
                if (insideFolder !== null) {
                  navigate(`?folder=${folderId}&parent=${insideFolder}`, {
                    replace: true,
                  });
                } else {
                  navigate(`?folder=${folderId}`, {
                    replace: true,
                  });
                }
              }}
            >
              {value.name} <FolderAddOutlined />
            </Button>
          );
        } else {
          return (
            <Tooltip placement="top" title={value.name}>
              <span>{value.name}</span>
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "fileType",
      key: "fileType",
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "fileType"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.fileType - b?.fileType,
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("fileType", "ascend");
          } else if (isDescendingClick) {
            handleSort("fileType", "descend");
          }
        },
      }),
      render: (_, value) => {
        return (
          <Tooltip placement="top" title={value?.fileType}>
            <span>{value?.fileType}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      ellipsis: {
        showTitle: false,
      },
      render: (_, value) => {
        if (value?.type === 0) {
          return (
            <Tooltip placement="top" title={value?.item}>
              <span>{value?.item}</span>
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "itemType",
      ellipsis: {
        showTitle: false,
      },
      render: (_, value) => {
        if (value?.type === 1) {
          return (
            <Tooltip placement="top" title={value?.itemType}>
              <span>{value?.itemType}</span>
            </Tooltip>
          );
        }
      },
    },
    {
      title: "Association Date",
      dataIndex: "associationDate",
      key: "associationDate",
      render: (text, record) => (
        <span>
          {text &&
            dateTimeConversionBasedOnTimeZone(
              text,
              displayTimeFormat,
              currentOrganization.appTimeZone
            )}
        </span>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (text, record) => {
        return (
          <span>
            {text &&
              dateTimeConversionBasedOnTimeZone(
                text,
                displayTimeFormat,
                currentOrganization.appTimeZone
              )}
          </span>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "created"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        const dateA = new Date(a?.created);
        const dateB = new Date(b?.created);

        // Compare the dates for sorting
        return dateA - dateB;
      },
      onHeaderCell: () => ({
        onClick: (e) => {
          const isAscendingClick = e.target.closest(
            ".ant-table-column-sorter-up"
          );
          const isDescendingClick = e.target.closest(
            ".ant-table-column-sorter-down"
          );
          if (isAscendingClick) {
            handleSort("created", "ascend");
          } else if (isDescendingClick) {
            handleSort("created", "descend");
          }
        },
      }),
    },
    {
      title: "Action & Associate Document",
      dataIndex: "action",
      key: "action",
      width: 250,
      render: (_, value) => {
        return (
          <>
            <Space>
              {hasUpdateAccess && (
                <Button
                  type="link"
                  className="p-0 mr-10"
                  onClick={() => {
                    setDocumentToEdit([
                      ...documentData?.filter((item) => item?.id === value?.id),
                    ]);
                    setDocumentEditModal(true);
                  }}
                >
                  <EditOutlined />
                </Button>
              )}

              {hasDeleteAccess && (
                <Button
                  type="link"
                  className="p-0 mr-10 redBtn"
                  onClick={() => {
                    setDeleteDoc(true);
                    setDocumentId(value?.id);
                    setDocumentToEdit([
                      ...documentData?.filter((item) => item?.id === value?.id),
                    ]);
                    setMultipleDelete(false);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              )}

              {hasUpdateAccess && (
                <Space>
                  {value.fileType === "Folder" ? null : (
                    <Button
                      type="link"
                      className="p-0 mr-10 blueBtn"
                      onClick={() => {
                        setDocumentId(value?.id);
                        setOpenActionModel(true);
                        setActionType(0);
                      }}
                    >
                      Item
                    </Button>
                  )}
                  {value.fileType === "Folder" ? null : (
                    <Button
                      type="link"
                      className="p-0  mr-10 blueBtn"
                      onClick={() => {
                        setDocumentId(value?.id);
                        setOpenActionModel(true);
                        setActionType(1);
                      }}
                    >
                      Item Type
                    </Button>
                  )}
                </Space>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  const resetSelection = () => {
    setSelectedDocuments([]);
    setSelectedKeys([]);
  };

  const customDeleteFunction = () => {
    setDocumentId("");
    fetchDocuments(
      allFolders,
      null,
      sortTableOrder,
      sortTableColumn,
      searchQuery
    );
    setDeleteDoc(false);
    setNextToken(null);
    setDocumentData([]);
    setDocumentEditModal(false);
    setDocumentToEdit([]);
    setMultipleDelete(false);
    resetSelection(); // Add explicit reset of selection
  };

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDocuments(selectedRows);
      setSelectedKeys(selectedRowKeys);
    },
  };

  // fetch document data function :: begins
  const fetchDocuments = async (
    folders,
    tokenData,
    sortOrder,
    sortColumn,
    search
  ) => {
    setSelectedDocuments([]);
    setSelectedKeys([]);

    setLoadingTable(true);
    try {
      const keyword = search || "";
      const limit = 20;
      const sortBy = sortColumn;
      const sortDir = sortOrder;

      const folderResponse = await searchDocumentsCustom(
        {
          organizationID: { eq: userOrganizationId },
          isDeleted: { ne: true },
          fileType: { eq: "Folder" },
          isFromMobile: { ne: true },
        },
        {},
        999,
        null,
        currentIdToken
      );
      const tree = createTree(folderResponse?.items);
      setTreeData(tree);

      const folders = [...folderResponse?.items];

      let filter = {
        organizationID: { eq: userOrganizationId },
        isFromMobile: { ne: true },
        isDeleted: { ne: true },
        and: insideFolder
          ? { parentId: { eq: insideFolder } }
          : folders.map((item) => ({ parentId: { ne: item?.id } })),
        or: { name: { wildcard: `*${keyword}*` } },
      };

      const sort = {
        field: "createdAt",
        direction: "desc",
      };
      if (sortDir && sortBy) {
        sort.field = sortBy === "created" ? "createdAt" : sortBy;
        sort.direction = sortDir;
      }
      // fetchFolders();

      const response = await searchDocumentsCustom(
        filter,
        sort,
        limit,
        tokenData,
        currentIdToken
      );

      if (!tokenData) {
        setDocumentData([...response?.items]);
      } else {
        setDocumentData((prevData) => [...prevData, ...response?.items]);
      }

      setNextTokenData(response?.nextToken);
    } catch (error) {
      setLoadingScrolls(false);
      CommonError(error);
    } finally {
      setLoadingScrolls(false);
      setLoadingTable(false);
    }
  };
  // fetch document data function :: end

  // fetch documentAttachment data :: begin
  const fetchAttachmentData = async (id, bulkEdit = false) => {
    try {
      const response = await documentAttachmentsByDocumentId(
        id,
        currentIdToken
      );
      if (!bulkEdit) {
        setEditData([...response?.items]);
      } else {
        setEditData((prevData) => [...prevData, ...response?.items]);
      }

      if (response?.items.length > 0) {
        setActionType(response?.items[0]?.type);
      }
    } catch (error) {
      CommonError(error);
    }
  };
  // fetch documentAttachment data :: end

  useEffect(() => {
    if (openActionModel || (deleteDoc && !multipleDelete)) {
      if (typeof documentId === "string") {
        fetchAttachmentData(documentId);
      }
    } else {
      setEditData([]);
    }
  }, [openActionModel, deleteDoc]);

  useEffect(() => {
    if (open) {
      selectedDocuments?.map((item) => {
        fetchAttachmentData(item?.id, true);
      });
    } else {
      setEditData([]);
      setSelectedDocuments([]);
      setSelectedKeys([]);
    }
  }, [open]);

  useEffect(() => {
    if (editData?.length !== 0) {
      setActionType(editData[0]?.type);
    }
  }, [editData]);
  // fetch documentAttachment data :: end

  const fetchDocumentWithTimeout = (delay = 3500) => {
    setTimeout(() => {
      fetchDocuments(
        allFolders,
        null,
        sortTableOrder,
        sortTableColumn,
        searchQuery
      );
    }, delay);
  };

  useEffect(() => {
    refetchDocs();
  }, [
    addNewFolderModal,
    documentEditModal,
    deleteDoc,
    allFolders,
    insideFolder,
    parentFolder,
    searchQuery,
    open,
    addNewDoc,
  ]);

  const refetchDocs = () => {
    const shouldFetch =
      !documentEditModal &&
      !addNewFolderModal &&
      !addNewDoc &&
      !deleteDoc &&
      !open &&
      !openActionModel;

    if (shouldFetch) {
      fetchDocuments(
        allFolders,
        null,
        sortTableOrder,
        sortTableColumn,
        searchQuery
      ); // Use the same function with a different delay
    }
  };

  useEffect(() => {
    if (!openActionModel) {
      setLoadingTable(true);
      fetchDocuments(
        allFolders,
        null,
        sortTableOrder,
        sortTableColumn,
        searchQuery
      );
    }
  }, [openActionModel]);

  const handleChange = async (pagination, sortData) => {
    let sortOrder = sortData.order === "ascend" ? "asc" : "desc";
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setNextTokenData(null);
    setDocumentData([]);
    await fetchDocuments(allFolders, null, sortOrder, sortColumn, searchQuery);
  };
  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextTokenData) {
      setLoadingScrolls(true);
      fetchDocuments(
        allFolders,
        nextTokenData,
        sortTableOrder,
        sortTableColumn,
        searchQuery
      );
    }
  };

  const handleInputSearch = (search) => {
    setNextTokenData(null);
    debouncedHandleSearch(search);
  };
  const handleSearch = (value) => {
    setSearchQuery(value.target.value);
    setNextTokenData(null); // Reset next token for new search
  };

  const debouncedHandleSearch = debounceFn(handleSearch, 1000);

  const Rows = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onRow = (record) => ({
    draggable: record.fileType !== "Folder" && true,
    onDragStart: (e) => {
      setDraggedItem(record);
      e.dataTransfer.setData(
        "text/plain",
        JSON.stringify({ id: record.id, filename: record.filename })
      );
    },
    onDragOver: (e) => {
      e.preventDefault();
      setDragOverItem(record);
    },
    onDragEnd: async () => {
      if (draggedItem && dragOverItem && draggedItem.key !== dragOverItem.key) {
        if (dragOverItem.fileType === "Folder") {
          const input = {
            id: draggedItem.id,
            parentId: dragOverItem.id,
          };

          await updateDocuments(input, currentIdToken);
          fetchDocuments(
            allFolders,
            null,
            sortTableOrder,
            sortTableColumn,
            searchQuery
          );
        }
      }
      setDraggedItem(null);
      setDragOverItem(null);
    },
    style: {
      cursor: draggedItem?.key === record.key ? "grabbing" : "grab",
      opacity: draggedItem?.key === record.key ? 0.5 : 1,
      background: dragOverItem?.key === record.key ? "#f5f5f5" : "white",
    },
  });

  useEffect(() => {
    if (!open && !openActionModel) {
      setEditData([]);
    }
  }, [openActionModel, open]);

  return (
    <div className="background-white">
      <Row justify="space-between page-action-wrapper">
        <Space>
          {insideFolder && (
            <Button
              type="text"
              onClick={() => {
                if (!parentFolder && insideFolder) {
                  navigate(window.location.pathname, { replace: true });
                } else {
                  navigate(`?folder=${parentFolder}`, { replace: true });
                }
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          )}
          <Search
            className="searchbar w-300"
            placeholder="Search"
            style={{
              width: 300,
            }}
            onChange={handleInputSearch}
          />
        </Space>
        <Row className="dashboard-action-btn-wrap mt-sm-20">
          {hasDeleteAccess && (
            <Button
              shape="round"
              onClick={() => {
                setDeleteDoc(true);
                setMultipleDelete(true);
              }}
              disabled={selectedDocuments.length > 1 ? false : true}
              danger
            >
              <DeleteOutlined /> Delete Selected
            </Button>
          )}

          {hasUpdateAccess && (
            <Button
              shape="round"
              onClick={() => setOpen(true)}
              disabled={
                selectedDocuments.filter((item) => item.fileType !== "Folder")
                  .length > 1
                  ? false
                  : true
              }
            >
              <EditOutlined /> Edit Bulk Document
            </Button>
          )}

          {hasCreateAccess && parentFolder === null && (
            <Button
              shape="round"
              onClick={() => {
                setAddNewFolderModal(true);
              }}
            >
              <FolderAddOutlined /> Add New Folder
            </Button>
          )}
          {hasCreateAccess && (
            <Button
              shape="round"
              className="dark-primary-btn"
              type="primary"
              onClick={() => {
                setAddNewDoc(true);
              }}
            >
              <PlusOutlined /> Add New Documents
            </Button>
          )}
        </Row>
      </Row>

      <SearchableTableWithSorting
        onRow={onRow}
        rowKey="id"
        screens={screens}
        columns={columns}
        data={hasReadAccesss ? columnData : null}
        rowSelection={rowSelection}
        hideSelection={false}
        loading={loadingTable && !loadingScrolls}
        loadData={loadingScrolls}
        handleScroll={(e) => handelInfiniteScroll(e)}
        handleChange={() => {}}
      />

      {hasUpdateAccess && (
        <EditDocuments
          documentEditModal={documentEditModal}
          setDocumentEditModal={() => setDocumentEditModal()}
          documentToEdit={documentToEdit}
          currentIdToken={currentIdToken}
          handleCustomSubmit={() => {
            customDeleteFunction();
            fetchDocuments(
              allFolders,
              null,
              sortTableOrder,
              sortTableColumn,
              searchQuery
            );
          }}
          treeData={treeData}
          folderArray={folderArray}
        />
      )}

      {hasDeleteAccess && (
        <DeleteDocument
          deleteDoc={deleteDoc}
          setDeleteDoc={() => setDeleteDoc()}
          handleDelete={() => customDeleteFunction()}
          documentId={documentId}
          currentIdToken={currentIdToken}
          deleteAttachments={editData}
          documentToDelete={documentToEdit}
          userOrganizationId={userOrganizationId}
          idArray={selectedDocuments}
          multiDelete={multipleDelete}
          onDeleteSuccess={resetSelection} // Add this new prop
        />
      )}

      {hasCreateAccess && (
        <AddNewFolder
          addNewFolderModal={addNewFolderModal}
          setAddNewFolderModal={() => setAddNewFolderModal()}
          folderKey={insideFolder}
          userOrganizationId={userOrganizationId}
          currentIdToken={currentIdToken}
        />
      )}

      {/* action model :: begin */}
      {hasUpdateAccess && (
        <SingleEdit
          singleEdit={openActionModel}
          setSingleEdit={() => setOpenActionModel()}
          projectData={projectList}
          itemTypeData={itemTypesList}
          organizationID={userOrganizationId}
          currentIdToken={currentIdToken}
          editDocument={true}
          activeForm={actionType}
          editToAttachment={editData}
          documentId={documentId}
          handleCustomForm={(data) => {
            setOpenActionModel(false);
            setLoadingTable(data);
          }}
          bulkEdit={false}
        />
      )}
      {/* action model :: end */}

      {hasCreateAccess && (
        <AddNewDocument
          addNewDoc={addNewDoc}
          setAddNewDoc={() => setAddNewDoc()}
          userOrganizationId={userOrganizationId}
          currentIdToken={currentIdToken}
          folderKey={insideFolder}
          parentKey={parentFolder}
          treeData={treeData}
          folderArray={folderArray}
          sequence={columnData?.length}
          handleCustomForm={(data) => {
            fetchDocumentWithTimeout();
          }}
          handleDocCustom={(data) => {
            if (data === "Close") {
              refetchDocs();
            } else {
              fetchDocumentWithTimeout();
            }
          }}
          handleAssociateData={(data) => {
            setDocumentId(data[0]?.id);
            setOpenActionModel(true);
          }}
        />
      )}

      {hasUpdateAccess && open && (
        <BulkEdit
          open={open}
          screens={screens}
          setOpen={() => setOpen()}
          editDocument={true}
          editToAttachment={editData}
          selectedDocuments={selectedDocuments.filter(
            (item) => item.fileType !== "Folder"
          )}
          organizationID={userOrganizationId}
          currentTokenId={currentIdToken}
          projectData={projectList}
          itemTypeData={itemTypesList}
          handleCustomBulkEdit={() => {
            setOpen(false);
          }}
          actionType={actionType}
        />
      )}
    </div>
  );
}
