import React, { useRef, useState, useEffect, useCallback } from "react";
import { Modal, Button, Input, message, Alert } from "antd";
import { Formik, Form, Field } from "formik";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Select, Space } from "antd";
import CreateNotificationGroup from "./CreateGroup";
import * as Yup from "yup";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import {
  updateNotifications,
  notificationGroupsByOrganizationID,
  listUserOfOrganization,
  listOrganizationUserRoles,
} from "utils/Actions";
import { useSelector } from "react-redux";
import {
  getCurrentIdToken,
  getOrganizationId,
  getCurrentUser,
} from "store/slices/loginSlice";
import { CommonError } from "utils/Helper/CommonError";
import { createnotification } from "utils/RESTApi";

const CreateNotification = ({
  open,
  onCancel,
  loading: initialLoading,
  notificationConfigDataEdit,
  notificationData,
}) => {
  const formikRef = useRef();
  const { TextArea } = Input;
  const [createNotificationGroupVisible, setCreateNotificationGroupVisible] =
    useState(false);
  const [externalContactWarning, setExternalContactWarning] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [notificationGroups, setNotificationGroups] = useState([]);
  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentIdToken);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(getCurrentUser);

  // Fetch organization users
  const fetchOrganizationUsers = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        organizationID: { eq: userOrganizationId },
      };
      const response = await listOrganizationUserRoles(filter, currentIdToken);

      const user = response?.items?.filter(
        (item) =>
          item?.User?.isEnabled &&
          !item?.User?.isDeleted &&
          item?.User?.id !== currentUser?.sub
      );

      const transformedOptions = user.map((item) => ({
        value: item.User.id,
        label: `${item.User.firstName} ${item.User.lastName}`,
        email: item.User.email,
      }));

      setContactOptions(transformedOptions);
    } catch (err) {
      CommonError(err);
    } finally {
      setLoading(false);
    }
  }, [userOrganizationId, currentIdToken]);

  // Fetch Notification Groups
  const fetchNotificationGroups = useCallback(async () => {
    const filter = {
      organizationID: { eq: userOrganizationId },
    };

    try {
      const response = await notificationGroupsByOrganizationID(
        userOrganizationId,
        currentIdToken,
        filter
      );
      const groups = response?.items || [];
      setNotificationGroups(groups);

      // Prepare group options from fetched groups
      const preparedGroupOptions = groups.map((group) => ({
        value: group.id,
        label: group.name,
      }));
      setGroupOptions(preparedGroupOptions);
    } catch (error) {
      CommonError(error);
    }
  }, [userOrganizationId, currentIdToken]);

  // Fetch users and groups on component mount or when organization changes
  useEffect(() => {
    if (open) {
      fetchOrganizationUsers();
      fetchNotificationGroups();
    }
  }, [open, fetchOrganizationUsers, fetchNotificationGroups]);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Notification name is required")
      .min(3, "Notification name must be at least 3 characters")
      .max(50, "Notification name must not exceed 50 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters"),
    memberIds: Yup.array().when(["groupIds"], {
      is: (groupIds) => groupIds === null || groupIds.length === 0,
      then: () =>
        Yup.array()
          .min(1, "Select at least one contact or any one Group")
          .required("Select at least one contact or any one Group"),
      otherwise: () => Yup.array().nullable(),
    }),
    // groupIds: Yup.array().nullable(),
  });

  // Initial Values
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: "",
    description: "",
    memberIds: [],
    groupIds: [],
  });

  // Prepare contact and initial values
  useEffect(() => {
    if (notificationConfigDataEdit && notificationData) {
      // Set initial values for edit scenario
      setInitialValues({
        id: notificationData.id,
        name: notificationData.name || "",
        description: notificationData.description || "",
        memberIds: notificationData.memberIds || [],
        groupIds: notificationData.groupIds || [],
      });
    } else {
      // Reset to default initial values
      setInitialValues({
        id: null,
        name: "",
        description: "",
        memberIds: [],
        groupIds: [],
      });
    }
  }, [notificationConfigDataEdit, notificationData]);

  // Handle form submission
  // In the handleSubmit function, modify the update logic
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      // Validate all fields
      await validationSchema.validate(values, { abortEarly: false });

      // Prepare mutation input exactly matching the GraphQL schema
      const data = {
        id: values.id,
        organizationID: userOrganizationId,
        name: values.name,
        description: values.description,
      };

      // Conditional logic for memberIds
      if (notificationConfigDataEdit) {
        // For update, just send memberIds array
        data.memberIds = values.memberIds;
      } else {
        // For create, transform memberIds
        data.memberIds = values.memberIds.map((memberId) => {
          const contact = contactOptions.find(
            (option) => option.value === memberId
          );
          return {
            id: contact.value,
            email: contact.email,
          };
        });

        // Additional fields for create scenario
        data.groupIds = values.groupIds || [];
        data.createdBy = currentUser?.sub;
      }

      // Call create or update mutation
      const response = notificationConfigDataEdit
        ? await updateNotifications(data, currentIdToken)
        : await createnotification(data);

      // Show success message
      message.success(
        notificationConfigDataEdit
          ? "Notification Updated Successfully"
          : "Notification Created Successfully"
      );

      // Reset form and close modal
      onCancel();
      setLoading(false);
      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    } catch (err) {
      CommonError(err);
      setLoading(false);
    } finally {
      setSubmitting(false);
    }
  };
  // Handle contact change with external contact warning
  const handleContactChange = (value, setFieldValue) => {
    // Determine external contacts by comparing against contactOptions
    const externalContacts = value.filter(
      (contact) => !contactOptions.some((opt) => opt.value === contact)
    );

    // Check if any external contacts are being added
    if (externalContacts.length > 0) {
      const warningMessage =
        externalContacts.length === 1
          ? `Are you trying to add an external user: ${externalContacts[0]}?`
          : `Are you trying to add ${externalContacts.length} external users?`;

      setExternalContactWarning(warningMessage);
    } else {
      // No external contacts, clear the warning
      setExternalContactWarning(null);
    }

    // Update the contacts field
    setFieldValue("memberIds", value);
  };

  // Handle modal cancel
  const handleCancel = () => {
    // Reset the form
    if (formikRef.current) {
      formikRef.current.resetForm();
    }

    // Clear external contact warning
    setExternalContactWarning(null);

    // Call the original onCancel prop
    onCancel();
  };

  // Handle closing of Create Group modal
  const handleCreateGroupCancel = () => {
    setCreateNotificationGroupVisible(false);
    fetchNotificationGroups();
  };

  return (
    <Modal
      title={
        notificationConfigDataEdit ? "Edit Notification" : "Create Notification"
      }
      className="modal"
      open={open}
      width={350}
      centered
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            if (formikRef.current) {
              formikRef.current.resetForm();
            }

            // Clear external contact warning
            setExternalContactWarning(null);
          }}
        >
          Reset
        </Button>,
        <Button
          key="submit"
          type="primary"
          form="CreateNotification"
          htmlType="submit"
          loading={loading}
        >
          {notificationConfigDataEdit ? "Update" : "Send"}
        </Button>,
      ]}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize={true}
        validateOnMount={false}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form id="CreateNotification" className="delete-modal">
            <div className="mb-15">
              <label htmlFor="name">Notification Name</label>
              <Field
                type="text"
                name="name"
                as={Input}
                placeholder="Enter Notification Name"
              />
              {touched.name && errors.name && (
                <ErrorMessageComponent error={errors.name} />
              )}
            </div>

            <div className="mb-10">
              <label htmlFor="description">Description</label>
              <Field
                as={TextArea}
                placeholder="Enter Description"
                name="description"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
              {touched.description && errors.description && (
                <ErrorMessageComponent error={errors.description} />
              )}
            </div>

            <div className="mb-10">
              <label htmlFor="memberIds">Select Contacts</label>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Select
                  name="memberIds"
                  style={{ width: "100%" }}
                  mode="multiple"
                  className="custom_multiselection"
                  allowClear
                  placeholder="Please select Contacts"
                  onChange={(value) =>
                    handleContactChange(value, setFieldValue)
                  }
                  options={contactOptions}
                  value={values?.memberIds}
                  loading={loading}
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase()) ||
                    option.email.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Space>
              {touched.memberIds && errors.memberIds && (
                <ErrorMessageComponent error={errors.memberIds} />
              )}
            </div>

            <div className="mb-10">
              <label htmlFor="groupIds">Group List (optional)</label>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Select
                  name="groupIds"
                  style={{ width: "100%" }}
                  mode="multiple"
                  className="custom_multiselection"
                  allowClear
                  placeholder="Please select groups"
                  onChange={(value) => setFieldValue("groupIds", value)}
                  options={groupOptions}
                  value={values.groupIds}
                  loading={loading}
                />
              </Space>
              {touched.groupIds && errors.groupIds && (
                <ErrorMessageComponent error={errors.groupIds} />
              )}
            </div>

            <div>
              <Button
                type="link"
                className="text-primary fs-14"
                onClick={() => setCreateNotificationGroupVisible(true)}
              >
                <PlusCircleOutlined /> Create Group
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <CreateNotificationGroup
        open={createNotificationGroupVisible}
        onCancel={handleCreateGroupCancel}
        loading={loading}
        notificationConfigDataEdit={notificationConfigDataEdit}
      />
    </Modal>
  );
};

export default CreateNotification;
