/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOpenSearchErrors = /* GraphQL */ `
  mutation CreateOpenSearchErrors(
    $input: CreateOpenSearchErrorsInput!
    $condition: ModelOpenSearchErrorsConditionInput
  ) {
    createOpenSearchErrors(input: $input, condition: $condition) {
      id
      data
      error
      eventName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOpenSearchErrors = /* GraphQL */ `
  mutation UpdateOpenSearchErrors(
    $input: UpdateOpenSearchErrorsInput!
    $condition: ModelOpenSearchErrorsConditionInput
  ) {
    updateOpenSearchErrors(input: $input, condition: $condition) {
      id
      data
      error
      eventName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOpenSearchErrors = /* GraphQL */ `
  mutation DeleteOpenSearchErrors(
    $input: DeleteOpenSearchErrorsInput!
    $condition: ModelOpenSearchErrorsConditionInput
  ) {
    deleteOpenSearchErrors(input: $input, condition: $condition) {
      id
      data
      error
      eventName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRedshiftOperationErrorLogs = /* GraphQL */ `
  mutation CreateRedshiftOperationErrorLogs(
    $input: CreateRedshiftOperationErrorLogsInput!
    $condition: ModelRedshiftOperationErrorLogsConditionInput
  ) {
    createRedshiftOperationErrorLogs(input: $input, condition: $condition) {
      id
      error_message
      deadLockError
      query
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRedshiftOperationErrorLogs = /* GraphQL */ `
  mutation UpdateRedshiftOperationErrorLogs(
    $input: UpdateRedshiftOperationErrorLogsInput!
    $condition: ModelRedshiftOperationErrorLogsConditionInput
  ) {
    updateRedshiftOperationErrorLogs(input: $input, condition: $condition) {
      id
      error_message
      deadLockError
      query
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRedshiftOperationErrorLogs = /* GraphQL */ `
  mutation DeleteRedshiftOperationErrorLogs(
    $input: DeleteRedshiftOperationErrorLogsInput!
    $condition: ModelRedshiftOperationErrorLogsConditionInput
  ) {
    deleteRedshiftOperationErrorLogs(input: $input, condition: $condition) {
      id
      error_message
      deadLockError
      query
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganizationCopyErrorLogs = /* GraphQL */ `
  mutation CreateOrganizationCopyErrorLogs(
    $input: CreateOrganizationCopyErrorLogsInput!
    $condition: ModelOrganizationCopyErrorLogsConditionInput
  ) {
    createOrganizationCopyErrorLogs(input: $input, condition: $condition) {
      id
      error
      organizationId
      tableName
      type
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganizationCopyErrorLogs = /* GraphQL */ `
  mutation UpdateOrganizationCopyErrorLogs(
    $input: UpdateOrganizationCopyErrorLogsInput!
    $condition: ModelOrganizationCopyErrorLogsConditionInput
  ) {
    updateOrganizationCopyErrorLogs(input: $input, condition: $condition) {
      id
      error
      organizationId
      tableName
      type
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganizationCopyErrorLogs = /* GraphQL */ `
  mutation DeleteOrganizationCopyErrorLogs(
    $input: DeleteOrganizationCopyErrorLogsInput!
    $condition: ModelOrganizationCopyErrorLogsConditionInput
  ) {
    deleteOrganizationCopyErrorLogs(input: $input, condition: $condition) {
      id
      error
      organizationId
      tableName
      type
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      legacyUserId
      firstName
      lastName
      email
      Organization {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastLoggedIn
      isEnabled
      isDeleted
      modifiedBy
      deletedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Scheduler {
        items {
          id
          name
          description
          number
          itemTypeId
          qunatity
          constructionDate
          shipmentTime
          curingTime
          manufacturingDays
          labourAvailabilty
          moldInventoryValue
          createdAt
          updatedAt
          shipmentDate
          organizationID
          createdBy
          updatedBy
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      legacyUserId
      firstName
      lastName
      email
      Organization {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastLoggedIn
      isEnabled
      isDeleted
      modifiedBy
      deletedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Scheduler {
        items {
          id
          name
          description
          number
          itemTypeId
          qunatity
          constructionDate
          shipmentTime
          curingTime
          manufacturingDays
          labourAvailabilty
          moldInventoryValue
          createdAt
          updatedAt
          shipmentDate
          organizationID
          createdBy
          updatedBy
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      legacyUserId
      firstName
      lastName
      email
      Organization {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastLoggedIn
      isEnabled
      isDeleted
      modifiedBy
      deletedAt
      createdAt
      updatedAt
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Scheduler {
        items {
          id
          name
          description
          number
          itemTypeId
          qunatity
          constructionDate
          shipmentTime
          curingTime
          manufacturingDays
          labourAvailabilty
          moldInventoryValue
          createdAt
          updatedAt
          shipmentDate
          organizationID
          createdBy
          updatedBy
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      label
      description
      Users {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isEnabled
      enableShipment
      userLimit
      appTimeZone
      token
      expiryTime
      ssrsFolderName
      subscription
      createdAt
      updatedAt
      CustomProperties {
        items {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Customers {
        items {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      RfidBucketList {
        items {
          id
          organizationID
          integrationId
          projectId
          name
          description
          enrollmentRule
          qualifications
          bucketItems
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      label
      description
      Users {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isEnabled
      enableShipment
      userLimit
      appTimeZone
      token
      expiryTime
      ssrsFolderName
      subscription
      createdAt
      updatedAt
      CustomProperties {
        items {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Customers {
        items {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      RfidBucketList {
        items {
          id
          organizationID
          integrationId
          projectId
          name
          description
          enrollmentRule
          qualifications
          bucketItems
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      label
      description
      Users {
        items {
          id
          userId
          organizationId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationUserRole {
        items {
          id
          userID
          organizationID
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isEnabled
      enableShipment
      userLimit
      appTimeZone
      token
      expiryTime
      ssrsFolderName
      subscription
      createdAt
      updatedAt
      CustomProperties {
        items {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processes {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Customers {
        items {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultConfigurations {
        items {
          id
          url
          login
          password
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      OrganizationsIntegrations {
        items {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsConfigurations {
        items {
          id
          environment
          apiUrl
          apiType
          apiSegment
          apiSecurityKey
          apiSource
          isDeleted
          lastTestFetched
          modifiedBy
          organizationID
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Documents {
        items {
          id
          parentId
          name
          fileName
          fileType
          isDeleted
          sequence
          type
          extension
          organizationID
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          isFromMobile
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      RfidBucketList {
        items {
          id
          organizationID
          integrationId
          projectId
          name
          description
          enrollmentRule
          qualifications
          bucketItems
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createOrganizationUserRole = /* GraphQL */ `
  mutation CreateOrganizationUserRole(
    $input: CreateOrganizationUserRoleInput!
    $condition: ModelOrganizationUserRoleConditionInput
  ) {
    createOrganizationUserRole(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganizationUserRole = /* GraphQL */ `
  mutation UpdateOrganizationUserRole(
    $input: UpdateOrganizationUserRoleInput!
    $condition: ModelOrganizationUserRoleConditionInput
  ) {
    updateOrganizationUserRole(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganizationUserRole = /* GraphQL */ `
  mutation DeleteOrganizationUserRole(
    $input: DeleteOrganizationUserRoleInput!
    $condition: ModelOrganizationUserRoleConditionInput
  ) {
    deleteOrganizationUserRole(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomProperties = /* GraphQL */ `
  mutation CreateCustomProperties(
    $input: CreateCustomPropertiesInput!
    $condition: ModelCustomPropertiesConditionInput
  ) {
    createCustomProperties(input: $input, condition: $condition) {
      id
      name
      searchName
      type
      propertyOf
      isRequired
      showInApp
      totalInShipment
      useAsWeight
      modifiedBy
      isDeleted
      isDefault
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdownHistory {
        items {
          id
          options
          createdAt
          updatedAt
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processed {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateCustomProperties = /* GraphQL */ `
  mutation UpdateCustomProperties(
    $input: UpdateCustomPropertiesInput!
    $condition: ModelCustomPropertiesConditionInput
  ) {
    updateCustomProperties(input: $input, condition: $condition) {
      id
      name
      searchName
      type
      propertyOf
      isRequired
      showInApp
      totalInShipment
      useAsWeight
      modifiedBy
      isDeleted
      isDefault
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdownHistory {
        items {
          id
          options
          createdAt
          updatedAt
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processed {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteCustomProperties = /* GraphQL */ `
  mutation DeleteCustomProperties(
    $input: DeleteCustomPropertiesInput!
    $condition: ModelCustomPropertiesConditionInput
  ) {
    deleteCustomProperties(input: $input, condition: $condition) {
      id
      name
      searchName
      type
      propertyOf
      isRequired
      showInApp
      totalInShipment
      useAsWeight
      modifiedBy
      isDeleted
      isDefault
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      PropertyDropdown {
        items {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      PropertyDropdownHistory {
        items {
          id
          options
          createdAt
          updatedAt
          customPropertyId
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Processed {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSnapShot {
        items {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createPropertyDropdown = /* GraphQL */ `
  mutation CreatePropertyDropdown(
    $input: CreatePropertyDropdownInput!
    $condition: ModelPropertyDropdownConditionInput
  ) {
    createPropertyDropdown(input: $input, condition: $condition) {
      id
      name
      options
      isDeleted
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updatePropertyDropdown = /* GraphQL */ `
  mutation UpdatePropertyDropdown(
    $input: UpdatePropertyDropdownInput!
    $condition: ModelPropertyDropdownConditionInput
  ) {
    updatePropertyDropdown(input: $input, condition: $condition) {
      id
      name
      options
      isDeleted
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deletePropertyDropdown = /* GraphQL */ `
  mutation DeletePropertyDropdown(
    $input: DeletePropertyDropdownInput!
    $condition: ModelPropertyDropdownConditionInput
  ) {
    deletePropertyDropdown(input: $input, condition: $condition) {
      id
      name
      options
      isDeleted
      deletedAt
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createPropertyDropdownHistory = /* GraphQL */ `
  mutation CreatePropertyDropdownHistory(
    $input: CreatePropertyDropdownHistoryInput!
    $condition: ModelPropertyDropdownHistoryConditionInput
  ) {
    createPropertyDropdownHistory(input: $input, condition: $condition) {
      id
      options
      createdAt
      updatedAt
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updatePropertyDropdownHistory = /* GraphQL */ `
  mutation UpdatePropertyDropdownHistory(
    $input: UpdatePropertyDropdownHistoryInput!
    $condition: ModelPropertyDropdownHistoryConditionInput
  ) {
    updatePropertyDropdownHistory(input: $input, condition: $condition) {
      id
      options
      createdAt
      updatedAt
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deletePropertyDropdownHistory = /* GraphQL */ `
  mutation DeletePropertyDropdownHistory(
    $input: DeletePropertyDropdownHistoryInput!
    $condition: ModelPropertyDropdownHistoryConditionInput
  ) {
    deletePropertyDropdownHistory(input: $input, condition: $condition) {
      id
      options
      createdAt
      updatedAt
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createProcesses = /* GraphQL */ `
  mutation CreateProcesses(
    $input: CreateProcessesInput!
    $condition: ModelProcessesConditionInput
  ) {
    createProcesses(input: $input, condition: $condition) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processesCompletedProcessStepsId
      __typename
    }
  }
`;
export const updateProcesses = /* GraphQL */ `
  mutation UpdateProcesses(
    $input: UpdateProcessesInput!
    $condition: ModelProcessesConditionInput
  ) {
    updateProcesses(input: $input, condition: $condition) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processesCompletedProcessStepsId
      __typename
    }
  }
`;
export const deleteProcesses = /* GraphQL */ `
  mutation DeleteProcesses(
    $input: DeleteProcessesInput!
    $condition: ModelProcessesConditionInput
  ) {
    deleteProcesses(input: $input, condition: $condition) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processesCompletedProcessStepsId
      __typename
    }
  }
`;
export const createProcessSnapShot = /* GraphQL */ `
  mutation CreateProcessSnapShot(
    $input: CreateProcessSnapShotInput!
    $condition: ModelProcessSnapShotConditionInput
  ) {
    createProcessSnapShot(input: $input, condition: $condition) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processSnapShotCompletedProcessStepsId
      __typename
    }
  }
`;
export const updateProcessSnapShot = /* GraphQL */ `
  mutation UpdateProcessSnapShot(
    $input: UpdateProcessSnapShotInput!
    $condition: ModelProcessSnapShotConditionInput
  ) {
    updateProcessSnapShot(input: $input, condition: $condition) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processSnapShotCompletedProcessStepsId
      __typename
    }
  }
`;
export const deleteProcessSnapShot = /* GraphQL */ `
  mutation DeleteProcessSnapShot(
    $input: DeleteProcessSnapShotInput!
    $condition: ModelProcessSnapShotConditionInput
  ) {
    deleteProcessSnapShot(input: $input, condition: $condition) {
      id
      name
      searchName
      isGlobal
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyStringValue
      customPropertyNumberValue
      customPropertyDateValue
      isAllStepsOnOneScreen
      isCompleteInAnyOrder
      showProcessBasedOnCustomProperty
      templateId
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessPhases {
        items {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processSnapShotCompletedProcessStepsId
      __typename
    }
  }
`;
export const createProcessPhases = /* GraphQL */ `
  mutation CreateProcessPhases(
    $input: CreateProcessPhasesInput!
    $condition: ModelProcessPhasesConditionInput
  ) {
    createProcessPhases(input: $input, condition: $condition) {
      id
      name
      processID
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      order
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processPhasesCompletedProcessStepsId
      __typename
    }
  }
`;
export const updateProcessPhases = /* GraphQL */ `
  mutation UpdateProcessPhases(
    $input: UpdateProcessPhasesInput!
    $condition: ModelProcessPhasesConditionInput
  ) {
    updateProcessPhases(input: $input, condition: $condition) {
      id
      name
      processID
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      order
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processPhasesCompletedProcessStepsId
      __typename
    }
  }
`;
export const deleteProcessPhases = /* GraphQL */ `
  mutation DeleteProcessPhases(
    $input: DeleteProcessPhasesInput!
    $condition: ModelProcessPhasesConditionInput
  ) {
    deleteProcessPhases(input: $input, condition: $condition) {
      id
      name
      processID
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      order
      isDeleted
      deletedAt
      createdAt
      updatedAt
      ProcessSteps {
        items {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      processPhasesCompletedProcessStepsId
      __typename
    }
  }
`;
export const createProcessSteps = /* GraphQL */ `
  mutation CreateProcessSteps(
    $input: CreateProcessStepsInput!
    $condition: ModelProcessStepsConditionInput
  ) {
    createProcessSteps(input: $input, condition: $condition) {
      id
      name
      description
      phaseID
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      order
      type
      isRequired
      allowFailedStep
      hasNotApplicableOption
      defaultOption
      customPassLabel
      customFailLabel
      customNALabel
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultValueOfCustomProperty
      isHidden
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      mask
      isDefaultToCurrentDate
      decimalPlace
      lowerLimit
      upperLimit
      isComputedLimits
      lowerLimitFormula
      upperLimitFormula
      allowValuesOutsideOfRange
      tagName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateProcessSteps = /* GraphQL */ `
  mutation UpdateProcessSteps(
    $input: UpdateProcessStepsInput!
    $condition: ModelProcessStepsConditionInput
  ) {
    updateProcessSteps(input: $input, condition: $condition) {
      id
      name
      description
      phaseID
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      order
      type
      isRequired
      allowFailedStep
      hasNotApplicableOption
      defaultOption
      customPassLabel
      customFailLabel
      customNALabel
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultValueOfCustomProperty
      isHidden
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      mask
      isDefaultToCurrentDate
      decimalPlace
      lowerLimit
      upperLimit
      isComputedLimits
      lowerLimitFormula
      upperLimitFormula
      allowValuesOutsideOfRange
      tagName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteProcessSteps = /* GraphQL */ `
  mutation DeleteProcessSteps(
    $input: DeleteProcessStepsInput!
    $condition: ModelProcessStepsConditionInput
  ) {
    deleteProcessSteps(input: $input, condition: $condition) {
      id
      name
      description
      phaseID
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      order
      type
      isRequired
      allowFailedStep
      hasNotApplicableOption
      defaultOption
      customPassLabel
      customFailLabel
      customNALabel
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultValueOfCustomProperty
      isHidden
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      mask
      isDefaultToCurrentDate
      decimalPlace
      lowerLimit
      upperLimit
      isComputedLimits
      lowerLimitFormula
      upperLimitFormula
      allowValuesOutsideOfRange
      tagName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createItemTypes = /* GraphQL */ `
  mutation CreateItemTypes(
    $input: CreateItemTypesInput!
    $condition: ModelItemTypesConditionInput
  ) {
    createItemTypes(input: $input, condition: $condition) {
      id
      number
      name
      searchName
      searchNumber
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      type
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      productSegment
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      quantityRequired
      quantityProduced
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      informationUrl
      __typename
    }
  }
`;
export const updateItemTypes = /* GraphQL */ `
  mutation UpdateItemTypes(
    $input: UpdateItemTypesInput!
    $condition: ModelItemTypesConditionInput
  ) {
    updateItemTypes(input: $input, condition: $condition) {
      id
      number
      name
      searchName
      searchNumber
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      type
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      productSegment
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      quantityRequired
      quantityProduced
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      informationUrl
      __typename
    }
  }
`;
export const deleteItemTypes = /* GraphQL */ `
  mutation DeleteItemTypes(
    $input: DeleteItemTypesInput!
    $condition: ModelItemTypesConditionInput
  ) {
    deleteItemTypes(input: $input, condition: $condition) {
      id
      number
      name
      searchName
      searchNumber
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      type
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      productSegment
      isActive
      isDeleted
      deletedAt
      createdAt
      updatedAt
      quantityRequired
      quantityProduced
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      ItemTypeCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemTypeId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ForneyVaultSetting {
        items {
          id
          isEnabled
          userMerticUnits
          projectNumber
          title
          organizationID
          itemTypeId
          projectId
          labNamePropertyId
          diameterPropertyId
          lengthPropertyId
          expectedStrengthPropertyId
          strippingStrengthPropertyId
          pourDatePropertyId
          ageToBreaksString
          isDeleted
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      informationUrl
      __typename
    }
  }
`;
export const createItemTypeCustomProperties = /* GraphQL */ `
  mutation CreateItemTypeCustomProperties(
    $input: CreateItemTypeCustomPropertiesInput!
    $condition: ModelItemTypeCustomPropertiesConditionInput
  ) {
    createItemTypeCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateItemTypeCustomProperties = /* GraphQL */ `
  mutation UpdateItemTypeCustomProperties(
    $input: UpdateItemTypeCustomPropertiesInput!
    $condition: ModelItemTypeCustomPropertiesConditionInput
  ) {
    updateItemTypeCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteItemTypeCustomProperties = /* GraphQL */ `
  mutation DeleteItemTypeCustomProperties(
    $input: DeleteItemTypeCustomPropertiesInput!
    $condition: ModelItemTypeCustomPropertiesConditionInput
  ) {
    deleteItemTypeCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createItems = /* GraphQL */ `
  mutation CreateItems(
    $input: CreateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    createItems(input: $input, condition: $condition) {
      id
      serialNumber
      searchNumber
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      currentPhaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessComplete
      hasFailedSteps
      serialNumberType
      latitude
      sentToHicams
      longitude
      isDeleted
      createdAt
      updatedAt
      forneyTestPending
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateItems = /* GraphQL */ `
  mutation UpdateItems(
    $input: UpdateItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    updateItems(input: $input, condition: $condition) {
      id
      serialNumber
      searchNumber
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      currentPhaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessComplete
      hasFailedSteps
      serialNumberType
      latitude
      sentToHicams
      longitude
      isDeleted
      createdAt
      updatedAt
      forneyTestPending
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteItems = /* GraphQL */ `
  mutation DeleteItems(
    $input: DeleteItemsInput!
    $condition: ModelItemsConditionInput
  ) {
    deleteItems(input: $input, condition: $condition) {
      id
      serialNumber
      searchNumber
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      currentPhaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessComplete
      hasFailedSteps
      serialNumberType
      latitude
      sentToHicams
      longitude
      isDeleted
      createdAt
      updatedAt
      forneyTestPending
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ItemsCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          itemsId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemAttachments {
        items {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      ItemProcesses {
        items {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultLogs {
        items {
          id
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          httpMethodType
          createdAt
          updatedAt
          organizationID
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ForneyVaultTestResults {
        items {
          id
          itemsId
          itemSerialNumber
          maxStress
          maxLoad
          ageToBreak
          diameter
          length
          number
          labName
          labId
          technicianName
          technicianInitials
          actualBreakDayAndTime
          ageAtBreak
          fractureType
          isDeleted
          organizationID
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsLogs {
        items {
          id
          organizationID
          request
          response
          status
          sentOn
          isSent
          itemsId
          apiEndPoint
          productSegment
          createdAt
          updatedAt
          modifiedBy
          isResent
          producerType
          httpMethodType
          isDeleted
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createItemsCustomProperties = /* GraphQL */ `
  mutation CreateItemsCustomProperties(
    $input: CreateItemsCustomPropertiesInput!
    $condition: ModelItemsCustomPropertiesConditionInput
  ) {
    createItemsCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateItemsCustomProperties = /* GraphQL */ `
  mutation UpdateItemsCustomProperties(
    $input: UpdateItemsCustomPropertiesInput!
    $condition: ModelItemsCustomPropertiesConditionInput
  ) {
    updateItemsCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteItemsCustomProperties = /* GraphQL */ `
  mutation DeleteItemsCustomProperties(
    $input: DeleteItemsCustomPropertiesInput!
    $condition: ModelItemsCustomPropertiesConditionInput
  ) {
    deleteItemsCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createProjects = /* GraphQL */ `
  mutation CreateProjects(
    $input: CreateProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    createProjects(input: $input, condition: $condition) {
      id
      number
      name
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      isItemLimited
      isCompleted
      inStock
      completedAt
      isDeleted
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateProjects = /* GraphQL */ `
  mutation UpdateProjects(
    $input: UpdateProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    updateProjects(input: $input, condition: $condition) {
      id
      number
      name
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      isItemLimited
      isCompleted
      inStock
      completedAt
      isDeleted
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteProjects = /* GraphQL */ `
  mutation DeleteProjects(
    $input: DeleteProjectsInput!
    $condition: ModelProjectsConditionInput
  ) {
    deleteProjects(input: $input, condition: $condition) {
      id
      number
      name
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      isItemLimited
      isCompleted
      inStock
      completedAt
      isDeleted
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      ProjectCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          projectId
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      Shipments {
        items {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      ItemTypes {
        items {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        nextToken
        __typename
      }
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createProjectCustomProperties = /* GraphQL */ `
  mutation CreateProjectCustomProperties(
    $input: CreateProjectCustomPropertiesInput!
    $condition: ModelProjectCustomPropertiesConditionInput
  ) {
    createProjectCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateProjectCustomProperties = /* GraphQL */ `
  mutation UpdateProjectCustomProperties(
    $input: UpdateProjectCustomPropertiesInput!
    $condition: ModelProjectCustomPropertiesConditionInput
  ) {
    updateProjectCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteProjectCustomProperties = /* GraphQL */ `
  mutation DeleteProjectCustomProperties(
    $input: DeleteProjectCustomPropertiesInput!
    $condition: ModelProjectCustomPropertiesConditionInput
  ) {
    deleteProjectCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createTimeZones = /* GraphQL */ `
  mutation CreateTimeZones(
    $input: CreateTimeZonesInput!
    $condition: ModelTimeZonesConditionInput
  ) {
    createTimeZones(input: $input, condition: $condition) {
      id
      iana
      standardName
      timeDifference
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateTimeZones = /* GraphQL */ `
  mutation UpdateTimeZones(
    $input: UpdateTimeZonesInput!
    $condition: ModelTimeZonesConditionInput
  ) {
    updateTimeZones(input: $input, condition: $condition) {
      id
      iana
      standardName
      timeDifference
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteTimeZones = /* GraphQL */ `
  mutation DeleteTimeZones(
    $input: DeleteTimeZonesInput!
    $condition: ModelTimeZonesConditionInput
  ) {
    deleteTimeZones(input: $input, condition: $condition) {
      id
      iana
      standardName
      timeDifference
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createCustomers = /* GraphQL */ `
  mutation CreateCustomers(
    $input: CreateCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    createCustomers(input: $input, condition: $condition) {
      id
      name
      searchName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      isActive
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateCustomers = /* GraphQL */ `
  mutation UpdateCustomers(
    $input: UpdateCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    updateCustomers(input: $input, condition: $condition) {
      id
      name
      searchName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      isActive
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteCustomers = /* GraphQL */ `
  mutation DeleteCustomers(
    $input: DeleteCustomersInput!
    $condition: ModelCustomersConditionInput
  ) {
    deleteCustomers(input: $input, condition: $condition) {
      id
      name
      searchName
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      Projects {
        items {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      HiCamsSettings {
        items {
          id
          organizationID
          isEnabled
          restrictedByCustomer
          customerId
          producerType
          itemTypeId
          preStressedItemTypeId
          plantIdPropertyId
          materialDescProperty
          materialIdPropertyId
          materialTypeIdPropertyId
          concreteMixPropertyId
          pourDatePropertyId
          pourNumberPropertyId
          airContentPropertyId
          slumpPropertyId
          spreadPropertyId
          flowJRingPropertyId
          flowLBoxPropertyId
          bedNumberPropertyId
          qtyPropertyId
          contractNumberPropertyId
          standardStrengthIndPropertyId
          neopreneCapIndPropertyId
          cylinderDiameterPropertyId
          modifiedBy
          isDeleted
          createdAt
          updatedAt
          isAvailable
          userHiCamsSettingsId
          customPropertiesHiCamsSettingsId
          itemTypesHiCamsSettingsId
          customersHiCamsSettingsId
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      isActive
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createItemAttachments = /* GraphQL */ `
  mutation CreateItemAttachments(
    $input: CreateItemAttachmentsInput!
    $condition: ModelItemAttachmentsConditionInput
  ) {
    createItemAttachments(input: $input, condition: $condition) {
      id
      name
      fileName
      type
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdBy
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      itemAttachmentsCompletedProcessStepsId
      __typename
    }
  }
`;
export const updateItemAttachments = /* GraphQL */ `
  mutation UpdateItemAttachments(
    $input: UpdateItemAttachmentsInput!
    $condition: ModelItemAttachmentsConditionInput
  ) {
    updateItemAttachments(input: $input, condition: $condition) {
      id
      name
      fileName
      type
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdBy
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      itemAttachmentsCompletedProcessStepsId
      __typename
    }
  }
`;
export const deleteItemAttachments = /* GraphQL */ `
  mutation DeleteItemAttachments(
    $input: DeleteItemAttachmentsInput!
    $condition: ModelItemAttachmentsConditionInput
  ) {
    deleteItemAttachments(input: $input, condition: $condition) {
      id
      name
      fileName
      type
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdBy
      modifiedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      CompletedProcessSteps {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        phaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        itemProcessId
        ItemProcess {
          id
          itemsId
          processId
          currentPhaseId
          isProcessCompleted
          isDefaultProcess
          isDeleted
          organizationID
          createdBy
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        processStepId
        ProcessSteps {
          id
          name
          description
          phaseID
          order
          type
          isRequired
          allowFailedStep
          hasNotApplicableOption
          defaultOption
          customPassLabel
          customFailLabel
          customNALabel
          customPropertyId
          defaultValueOfCustomProperty
          isHidden
          propertyDropdownId
          mask
          isDefaultToCurrentDate
          decimalPlace
          lowerLimit
          upperLimit
          isComputedLimits
          lowerLimitFormula
          upperLimitFormula
          allowValuesOutsideOfRange
          tagName
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        stepType
        isDeleted
        deletedAt
        finishedOnUtc
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        notes
        isFailed
        result
        value
        valueOld
        dateValue
        dateValueOld
        latitude
        latitudeOld
        longitude
        longitudeOld
        numericValue
        numericValueOld
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        itemTypeId
        itemTypeIdOld
        customItemTypeIdOld
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        createdBy
        modifiedBy
        finishedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        pictureId
        isActive
        Picture {
          id
          name
          fileName
          type
          itemsId
          organizationID
          isDeleted
          deletedAt
          createdBy
          modifiedBy
          createdAt
          updatedAt
          isAvailable
          itemAttachmentsCompletedProcessStepsId
          __typename
        }
        createdAt
        updatedAt
        isAvailable
        isLegacy
        customPropertiesCompletedProcessStepsId
        itemProcessesCompletedProcessStepsId
        __typename
      }
      isAvailable
      itemAttachmentsCompletedProcessStepsId
      __typename
    }
  }
`;
export const createCompletedProcessSteps = /* GraphQL */ `
  mutation CreateCompletedProcessSteps(
    $input: CreateCompletedProcessStepsInput!
    $condition: ModelCompletedProcessStepsConditionInput
  ) {
    createCompletedProcessSteps(input: $input, condition: $condition) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      phaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      itemProcessId
      ItemProcess {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      processStepId
      ProcessSteps {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      stepType
      isDeleted
      deletedAt
      finishedOnUtc
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      notes
      isFailed
      result
      value
      valueOld
      dateValue
      dateValueOld
      latitude
      latitudeOld
      longitude
      longitudeOld
      numericValue
      numericValueOld
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      itemTypeIdOld
      customItemTypeIdOld
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      createdBy
      modifiedBy
      finishedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      pictureId
      isActive
      Picture {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      isLegacy
      customPropertiesCompletedProcessStepsId
      itemProcessesCompletedProcessStepsId
      __typename
    }
  }
`;
export const updateCompletedProcessSteps = /* GraphQL */ `
  mutation UpdateCompletedProcessSteps(
    $input: UpdateCompletedProcessStepsInput!
    $condition: ModelCompletedProcessStepsConditionInput
  ) {
    updateCompletedProcessSteps(input: $input, condition: $condition) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      phaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      itemProcessId
      ItemProcess {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      processStepId
      ProcessSteps {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      stepType
      isDeleted
      deletedAt
      finishedOnUtc
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      notes
      isFailed
      result
      value
      valueOld
      dateValue
      dateValueOld
      latitude
      latitudeOld
      longitude
      longitudeOld
      numericValue
      numericValueOld
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      itemTypeIdOld
      customItemTypeIdOld
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      createdBy
      modifiedBy
      finishedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      pictureId
      isActive
      Picture {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      isLegacy
      customPropertiesCompletedProcessStepsId
      itemProcessesCompletedProcessStepsId
      __typename
    }
  }
`;
export const deleteCompletedProcessSteps = /* GraphQL */ `
  mutation DeleteCompletedProcessSteps(
    $input: DeleteCompletedProcessStepsInput!
    $condition: ModelCompletedProcessStepsConditionInput
  ) {
    deleteCompletedProcessSteps(input: $input, condition: $condition) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      phaseId
      ProcessPhases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      itemProcessId
      ItemProcess {
        id
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        currentPhaseId
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessCompleted
        isDefaultProcess
        isDeleted
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      processStepId
      ProcessSteps {
        id
        name
        description
        phaseID
        Phases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        order
        type
        isRequired
        allowFailedStep
        hasNotApplicableOption
        defaultOption
        customPassLabel
        customFailLabel
        customNALabel
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        defaultValueOfCustomProperty
        isHidden
        propertyDropdownId
        PropertyDropdown {
          id
          name
          options
          isDeleted
          deletedAt
          createdAt
          updatedAt
          organizationID
          customPropertyId
          isAvailable
          __typename
        }
        mask
        isDefaultToCurrentDate
        decimalPlace
        lowerLimit
        upperLimit
        isComputedLimits
        lowerLimitFormula
        upperLimitFormula
        allowValuesOutsideOfRange
        tagName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdAt
        updatedAt
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      stepType
      isDeleted
      deletedAt
      finishedOnUtc
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      notes
      isFailed
      result
      value
      valueOld
      dateValue
      dateValueOld
      latitude
      latitudeOld
      longitude
      longitudeOld
      numericValue
      numericValueOld
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      itemTypeIdOld
      customItemTypeIdOld
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      createdBy
      modifiedBy
      finishedBy
      User {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      pictureId
      isActive
      Picture {
        id
        name
        fileName
        type
        itemsId
        Items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isDeleted
        deletedAt
        createdBy
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        itemAttachmentsCompletedProcessStepsId
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      isLegacy
      customPropertiesCompletedProcessStepsId
      itemProcessesCompletedProcessStepsId
      __typename
    }
  }
`;
export const createModules = /* GraphQL */ `
  mutation CreateModules(
    $input: CreateModulesInput!
    $condition: ModelModulesConditionInput
  ) {
    createModules(input: $input, condition: $condition) {
      id
      name
      key
      description
      createdAt
      updatedAt
      isDefault
      RoleBaseModuleAccess {
        id
        role
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        access
        deniedFields
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      OrganizationsIntegrations {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      modulesRoleBaseModuleAccessId
      modulesOrganizationsIntegrationsId
      __typename
    }
  }
`;
export const updateModules = /* GraphQL */ `
  mutation UpdateModules(
    $input: UpdateModulesInput!
    $condition: ModelModulesConditionInput
  ) {
    updateModules(input: $input, condition: $condition) {
      id
      name
      key
      description
      createdAt
      updatedAt
      isDefault
      RoleBaseModuleAccess {
        id
        role
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        access
        deniedFields
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      OrganizationsIntegrations {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      modulesRoleBaseModuleAccessId
      modulesOrganizationsIntegrationsId
      __typename
    }
  }
`;
export const deleteModules = /* GraphQL */ `
  mutation DeleteModules(
    $input: DeleteModulesInput!
    $condition: ModelModulesConditionInput
  ) {
    deleteModules(input: $input, condition: $condition) {
      id
      name
      key
      description
      createdAt
      updatedAt
      isDefault
      RoleBaseModuleAccess {
        id
        role
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        access
        deniedFields
        isAvailable
        createdAt
        updatedAt
        __typename
      }
      OrganizationsIntegrations {
        id
        moduleId
        Modules {
          id
          name
          key
          description
          createdAt
          updatedAt
          isDefault
          isAvailable
          modulesRoleBaseModuleAccessId
          modulesOrganizationsIntegrationsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        isSubscribed
        createdAt
        updatedAt
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        isAvailable
        __typename
      }
      isAvailable
      modulesRoleBaseModuleAccessId
      modulesOrganizationsIntegrationsId
      __typename
    }
  }
`;
export const createRoleBaseModuleAccess = /* GraphQL */ `
  mutation CreateRoleBaseModuleAccess(
    $input: CreateRoleBaseModuleAccessInput!
    $condition: ModelRoleBaseModuleAccessConditionInput
  ) {
    createRoleBaseModuleAccess(input: $input, condition: $condition) {
      id
      role
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      access
      deniedFields
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRoleBaseModuleAccess = /* GraphQL */ `
  mutation UpdateRoleBaseModuleAccess(
    $input: UpdateRoleBaseModuleAccessInput!
    $condition: ModelRoleBaseModuleAccessConditionInput
  ) {
    updateRoleBaseModuleAccess(input: $input, condition: $condition) {
      id
      role
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      access
      deniedFields
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRoleBaseModuleAccess = /* GraphQL */ `
  mutation DeleteRoleBaseModuleAccess(
    $input: DeleteRoleBaseModuleAccessInput!
    $condition: ModelRoleBaseModuleAccessConditionInput
  ) {
    deleteRoleBaseModuleAccess(input: $input, condition: $condition) {
      id
      role
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      access
      deniedFields
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createItemProcesses = /* GraphQL */ `
  mutation CreateItemProcesses(
    $input: CreateItemProcessesInput!
    $condition: ModelItemProcessesConditionInput
  ) {
    createItemProcesses(input: $input, condition: $condition) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      currentPhaseId
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessCompleted
      isDefaultProcess
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateItemProcesses = /* GraphQL */ `
  mutation UpdateItemProcesses(
    $input: UpdateItemProcessesInput!
    $condition: ModelItemProcessesConditionInput
  ) {
    updateItemProcesses(input: $input, condition: $condition) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      currentPhaseId
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessCompleted
      isDefaultProcess
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteItemProcesses = /* GraphQL */ `
  mutation DeleteItemProcesses(
    $input: DeleteItemProcessesInput!
    $condition: ModelItemProcessesConditionInput
  ) {
    deleteItemProcesses(input: $input, condition: $condition) {
      id
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      processId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      currentPhaseId
      Phases {
        id
        name
        processID
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        order
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessSteps {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processPhasesCompletedProcessStepsId
        __typename
      }
      isProcessCompleted
      isDefaultProcess
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdBy
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      CompletedProcessSteps {
        items {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        nextToken
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createForneyVaultConfigurations = /* GraphQL */ `
  mutation CreateForneyVaultConfigurations(
    $input: CreateForneyVaultConfigurationsInput!
    $condition: ModelForneyVaultConfigurationsConditionInput
  ) {
    createForneyVaultConfigurations(input: $input, condition: $condition) {
      id
      url
      login
      password
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateForneyVaultConfigurations = /* GraphQL */ `
  mutation UpdateForneyVaultConfigurations(
    $input: UpdateForneyVaultConfigurationsInput!
    $condition: ModelForneyVaultConfigurationsConditionInput
  ) {
    updateForneyVaultConfigurations(input: $input, condition: $condition) {
      id
      url
      login
      password
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteForneyVaultConfigurations = /* GraphQL */ `
  mutation DeleteForneyVaultConfigurations(
    $input: DeleteForneyVaultConfigurationsInput!
    $condition: ModelForneyVaultConfigurationsConditionInput
  ) {
    deleteForneyVaultConfigurations(input: $input, condition: $condition) {
      id
      url
      login
      password
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createForneyVaultSetting = /* GraphQL */ `
  mutation CreateForneyVaultSetting(
    $input: CreateForneyVaultSettingInput!
    $condition: ModelForneyVaultSettingConditionInput
  ) {
    createForneyVaultSetting(input: $input, condition: $condition) {
      id
      isEnabled
      userMerticUnits
      projectNumber
      title
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      projectId
      labNamePropertyId
      LabNameProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      diameterPropertyId
      DiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      lengthPropertyId
      LengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      expectedStrengthPropertyId
      ExpectedStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      strippingStrengthPropertyId
      StrippingStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ageToBreaksString
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateForneyVaultSetting = /* GraphQL */ `
  mutation UpdateForneyVaultSetting(
    $input: UpdateForneyVaultSettingInput!
    $condition: ModelForneyVaultSettingConditionInput
  ) {
    updateForneyVaultSetting(input: $input, condition: $condition) {
      id
      isEnabled
      userMerticUnits
      projectNumber
      title
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      projectId
      labNamePropertyId
      LabNameProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      diameterPropertyId
      DiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      lengthPropertyId
      LengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      expectedStrengthPropertyId
      ExpectedStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      strippingStrengthPropertyId
      StrippingStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ageToBreaksString
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteForneyVaultSetting = /* GraphQL */ `
  mutation DeleteForneyVaultSetting(
    $input: DeleteForneyVaultSettingInput!
    $condition: ModelForneyVaultSettingConditionInput
  ) {
    deleteForneyVaultSetting(input: $input, condition: $condition) {
      id
      isEnabled
      userMerticUnits
      projectNumber
      title
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      projectId
      labNamePropertyId
      LabNameProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      diameterPropertyId
      DiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      lengthPropertyId
      LengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      expectedStrengthPropertyId
      ExpectedStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      strippingStrengthPropertyId
      StrippingStrengthProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      ageToBreaksString
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createForneyVaultLogs = /* GraphQL */ `
  mutation CreateForneyVaultLogs(
    $input: CreateForneyVaultLogsInput!
    $condition: ModelForneyVaultLogsConditionInput
  ) {
    createForneyVaultLogs(input: $input, condition: $condition) {
      id
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      httpMethodType
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateForneyVaultLogs = /* GraphQL */ `
  mutation UpdateForneyVaultLogs(
    $input: UpdateForneyVaultLogsInput!
    $condition: ModelForneyVaultLogsConditionInput
  ) {
    updateForneyVaultLogs(input: $input, condition: $condition) {
      id
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      httpMethodType
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteForneyVaultLogs = /* GraphQL */ `
  mutation DeleteForneyVaultLogs(
    $input: DeleteForneyVaultLogsInput!
    $condition: ModelForneyVaultLogsConditionInput
  ) {
    deleteForneyVaultLogs(input: $input, condition: $condition) {
      id
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      httpMethodType
      createdAt
      updatedAt
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createForneyVaultTestResults = /* GraphQL */ `
  mutation CreateForneyVaultTestResults(
    $input: CreateForneyVaultTestResultsInput!
    $condition: ModelForneyVaultTestResultsConditionInput
  ) {
    createForneyVaultTestResults(input: $input, condition: $condition) {
      id
      itemsId
      itemSerialNumber
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      maxStress
      maxLoad
      ageToBreak
      diameter
      length
      number
      labName
      labId
      technicianName
      technicianInitials
      actualBreakDayAndTime
      ageAtBreak
      fractureType
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateForneyVaultTestResults = /* GraphQL */ `
  mutation UpdateForneyVaultTestResults(
    $input: UpdateForneyVaultTestResultsInput!
    $condition: ModelForneyVaultTestResultsConditionInput
  ) {
    updateForneyVaultTestResults(input: $input, condition: $condition) {
      id
      itemsId
      itemSerialNumber
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      maxStress
      maxLoad
      ageToBreak
      diameter
      length
      number
      labName
      labId
      technicianName
      technicianInitials
      actualBreakDayAndTime
      ageAtBreak
      fractureType
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteForneyVaultTestResults = /* GraphQL */ `
  mutation DeleteForneyVaultTestResults(
    $input: DeleteForneyVaultTestResultsInput!
    $condition: ModelForneyVaultTestResultsConditionInput
  ) {
    deleteForneyVaultTestResults(input: $input, condition: $condition) {
      id
      itemsId
      itemSerialNumber
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      maxStress
      maxLoad
      ageToBreak
      diameter
      length
      number
      labName
      labId
      technicianName
      technicianInitials
      actualBreakDayAndTime
      ageAtBreak
      fractureType
      isDeleted
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createOrganizationsIntegrations = /* GraphQL */ `
  mutation CreateOrganizationsIntegrations(
    $input: CreateOrganizationsIntegrationsInput!
    $condition: ModelOrganizationsIntegrationsConditionInput
  ) {
    createOrganizationsIntegrations(input: $input, condition: $condition) {
      id
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isSubscribed
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateOrganizationsIntegrations = /* GraphQL */ `
  mutation UpdateOrganizationsIntegrations(
    $input: UpdateOrganizationsIntegrationsInput!
    $condition: ModelOrganizationsIntegrationsConditionInput
  ) {
    updateOrganizationsIntegrations(input: $input, condition: $condition) {
      id
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isSubscribed
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteOrganizationsIntegrations = /* GraphQL */ `
  mutation DeleteOrganizationsIntegrations(
    $input: DeleteOrganizationsIntegrationsInput!
    $condition: ModelOrganizationsIntegrationsConditionInput
  ) {
    deleteOrganizationsIntegrations(input: $input, condition: $condition) {
      id
      moduleId
      Modules {
        id
        name
        key
        description
        createdAt
        updatedAt
        isDefault
        RoleBaseModuleAccess {
          id
          role
          moduleId
          access
          deniedFields
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        OrganizationsIntegrations {
          id
          moduleId
          organizationID
          isSubscribed
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          __typename
        }
        isAvailable
        modulesRoleBaseModuleAccessId
        modulesOrganizationsIntegrationsId
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isSubscribed
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createHiCamsSettings = /* GraphQL */ `
  mutation CreateHiCamsSettings(
    $input: CreateHiCamsSettingsInput!
    $condition: ModelHiCamsSettingsConditionInput
  ) {
    createHiCamsSettings(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isEnabled
      restrictedByCustomer
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      producerType
      itemTypeId
      ItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      preStressedItemTypeId
      PreStressedItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      plantIdPropertyId
      PlantIdPropertyId {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialDescProperty
      MaterialDescProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialIdPropertyId
      MaterialIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialTypeIdPropertyId
      MaterialTypeIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      concreteMixPropertyId
      ConcreteMixProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourNumberPropertyId
      PourNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      airContentPropertyId
      AirContentProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      slumpPropertyId
      SlumpProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      spreadPropertyId
      SpreadProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowJRingPropertyId
      FlowJRingProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowLBoxPropertyId
      FlowLBoxProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      bedNumberPropertyId
      BedNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      qtyPropertyId
      QtyProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      contractNumberPropertyId
      ContractNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      standardStrengthIndPropertyId
      StandardStrengthIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      neopreneCapIndPropertyId
      NeopreneCapIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      cylinderDiameterPropertyId
      CylinderDiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      isAvailable
      userHiCamsSettingsId
      customPropertiesHiCamsSettingsId
      itemTypesHiCamsSettingsId
      customersHiCamsSettingsId
      __typename
    }
  }
`;
export const updateHiCamsSettings = /* GraphQL */ `
  mutation UpdateHiCamsSettings(
    $input: UpdateHiCamsSettingsInput!
    $condition: ModelHiCamsSettingsConditionInput
  ) {
    updateHiCamsSettings(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isEnabled
      restrictedByCustomer
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      producerType
      itemTypeId
      ItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      preStressedItemTypeId
      PreStressedItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      plantIdPropertyId
      PlantIdPropertyId {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialDescProperty
      MaterialDescProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialIdPropertyId
      MaterialIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialTypeIdPropertyId
      MaterialTypeIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      concreteMixPropertyId
      ConcreteMixProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourNumberPropertyId
      PourNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      airContentPropertyId
      AirContentProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      slumpPropertyId
      SlumpProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      spreadPropertyId
      SpreadProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowJRingPropertyId
      FlowJRingProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowLBoxPropertyId
      FlowLBoxProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      bedNumberPropertyId
      BedNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      qtyPropertyId
      QtyProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      contractNumberPropertyId
      ContractNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      standardStrengthIndPropertyId
      StandardStrengthIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      neopreneCapIndPropertyId
      NeopreneCapIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      cylinderDiameterPropertyId
      CylinderDiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      isAvailable
      userHiCamsSettingsId
      customPropertiesHiCamsSettingsId
      itemTypesHiCamsSettingsId
      customersHiCamsSettingsId
      __typename
    }
  }
`;
export const deleteHiCamsSettings = /* GraphQL */ `
  mutation DeleteHiCamsSettings(
    $input: DeleteHiCamsSettingsInput!
    $condition: ModelHiCamsSettingsConditionInput
  ) {
    deleteHiCamsSettings(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isEnabled
      restrictedByCustomer
      customerId
      Customers {
        id
        name
        searchName
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        isDeleted
        isActive
        deletedAt
        createdAt
        updatedAt
        isAvailable
        __typename
      }
      producerType
      itemTypeId
      ItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      preStressedItemTypeId
      PreStressedItemType {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      plantIdPropertyId
      PlantIdPropertyId {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialDescProperty
      MaterialDescProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialIdPropertyId
      MaterialIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      materialTypeIdPropertyId
      MaterialTypeIdProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      concreteMixPropertyId
      ConcreteMixProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourDatePropertyId
      PourDateProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      pourNumberPropertyId
      PourNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      airContentPropertyId
      AirContentProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      slumpPropertyId
      SlumpProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      spreadPropertyId
      SpreadProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowJRingPropertyId
      FlowJRingProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      flowLBoxPropertyId
      FlowLBoxProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      bedNumberPropertyId
      BedNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      qtyPropertyId
      QtyProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      contractNumberPropertyId
      ContractNumberProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      standardStrengthIndPropertyId
      StandardStrengthIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      neopreneCapIndPropertyId
      NeopreneCapIndProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      cylinderDiameterPropertyId
      CylinderDiameterProperty {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      isAvailable
      userHiCamsSettingsId
      customPropertiesHiCamsSettingsId
      itemTypesHiCamsSettingsId
      customersHiCamsSettingsId
      __typename
    }
  }
`;
export const createHiCamsConfigurations = /* GraphQL */ `
  mutation CreateHiCamsConfigurations(
    $input: CreateHiCamsConfigurationsInput!
    $condition: ModelHiCamsConfigurationsConditionInput
  ) {
    createHiCamsConfigurations(input: $input, condition: $condition) {
      id
      environment
      apiUrl
      apiType
      apiSegment
      apiSecurityKey
      apiSource
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateHiCamsConfigurations = /* GraphQL */ `
  mutation UpdateHiCamsConfigurations(
    $input: UpdateHiCamsConfigurationsInput!
    $condition: ModelHiCamsConfigurationsConditionInput
  ) {
    updateHiCamsConfigurations(input: $input, condition: $condition) {
      id
      environment
      apiUrl
      apiType
      apiSegment
      apiSecurityKey
      apiSource
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteHiCamsConfigurations = /* GraphQL */ `
  mutation DeleteHiCamsConfigurations(
    $input: DeleteHiCamsConfigurationsInput!
    $condition: ModelHiCamsConfigurationsConditionInput
  ) {
    deleteHiCamsConfigurations(input: $input, condition: $condition) {
      id
      environment
      apiUrl
      apiType
      apiSegment
      apiSecurityKey
      apiSource
      isDeleted
      lastTestFetched
      modifiedBy
      ModifiedBy {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createHiCamsLogs = /* GraphQL */ `
  mutation CreateHiCamsLogs(
    $input: CreateHiCamsLogsInput!
    $condition: ModelHiCamsLogsConditionInput
  ) {
    createHiCamsLogs(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      productSegment
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isResent
      producerType
      httpMethodType
      isDeleted
      deletedAt
      isAvailable
      __typename
    }
  }
`;
export const updateHiCamsLogs = /* GraphQL */ `
  mutation UpdateHiCamsLogs(
    $input: UpdateHiCamsLogsInput!
    $condition: ModelHiCamsLogsConditionInput
  ) {
    updateHiCamsLogs(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      productSegment
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isResent
      producerType
      httpMethodType
      isDeleted
      deletedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteHiCamsLogs = /* GraphQL */ `
  mutation DeleteHiCamsLogs(
    $input: DeleteHiCamsLogsInput!
    $condition: ModelHiCamsLogsConditionInput
  ) {
    deleteHiCamsLogs(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      request
      response
      status
      sentOn
      isSent
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      apiEndPoint
      productSegment
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isResent
      producerType
      httpMethodType
      isDeleted
      deletedAt
      isAvailable
      __typename
    }
  }
`;
export const createShipments = /* GraphQL */ `
  mutation CreateShipments(
    $input: CreateShipmentsInput!
    $condition: ModelShipmentsConditionInput
  ) {
    createShipments(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      name
      searchNumber
      startWith
      number
      scheduleDate
      shippedDate
      notes
      isShipped
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      maximumWeight
      createdAt
      updatedAt
      deletedAt
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const updateShipments = /* GraphQL */ `
  mutation UpdateShipments(
    $input: UpdateShipmentsInput!
    $condition: ModelShipmentsConditionInput
  ) {
    updateShipments(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      name
      searchNumber
      startWith
      number
      scheduleDate
      shippedDate
      notes
      isShipped
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      maximumWeight
      createdAt
      updatedAt
      deletedAt
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const deleteShipments = /* GraphQL */ `
  mutation DeleteShipments(
    $input: DeleteShipmentsInput!
    $condition: ModelShipmentsConditionInput
  ) {
    deleteShipments(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      name
      searchNumber
      startWith
      number
      scheduleDate
      shippedDate
      notes
      isShipped
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      defaultProcessId
      Processes {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processesCompletedProcessStepsId
        __typename
      }
      ProcessSnapShot {
        id
        name
        searchName
        isGlobal
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        customPropertyStringValue
        customPropertyNumberValue
        customPropertyDateValue
        isAllStepsOnOneScreen
        isCompleteInAnyOrder
        showProcessBasedOnCustomProperty
        templateId
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        ProcessPhases {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          id
          itemsId
          organizationID
          processId
          phaseId
          itemProcessId
          processStepId
          stepType
          isDeleted
          deletedAt
          finishedOnUtc
          customPropertyId
          notes
          isFailed
          result
          value
          valueOld
          dateValue
          dateValueOld
          latitude
          latitudeOld
          longitude
          longitudeOld
          numericValue
          numericValueOld
          projectId
          itemTypeId
          itemTypeIdOld
          customItemTypeIdOld
          createdBy
          modifiedBy
          finishedBy
          pictureId
          isActive
          createdAt
          updatedAt
          isAvailable
          isLegacy
          customPropertiesCompletedProcessStepsId
          itemProcessesCompletedProcessStepsId
          __typename
        }
        isAvailable
        processSnapShotCompletedProcessStepsId
        __typename
      }
      isDeleted
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      maximumWeight
      createdAt
      updatedAt
      deletedAt
      ItemTypeInShipment {
        items {
          id
          organizationID
          shipmentId
          projectId
          itemTypeId
          quantity
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          projectsItemTypeInShipmentId
          __typename
        }
        nextToken
        __typename
      }
      Items {
        items {
          id
          serialNumber
          searchNumber
          itemTypeId
          processId
          projectId
          shipmentId
          currentPhaseId
          isProcessComplete
          hasFailedSteps
          serialNumberType
          latitude
          sentToHicams
          longitude
          isDeleted
          createdAt
          updatedAt
          forneyTestPending
          organizationID
          createdBy
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      ShipmentCustomProperties {
        items {
          id
          customPropertyId
          customPropertyValue
          shipmentId
          requiredProperty
          propertyDropdownId
          organizationID
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      __typename
    }
  }
`;
export const createShipmentCustomProperties = /* GraphQL */ `
  mutation CreateShipmentCustomProperties(
    $input: CreateShipmentCustomPropertiesInput!
    $condition: ModelShipmentCustomPropertiesConditionInput
  ) {
    createShipmentCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateShipmentCustomProperties = /* GraphQL */ `
  mutation UpdateShipmentCustomProperties(
    $input: UpdateShipmentCustomPropertiesInput!
    $condition: ModelShipmentCustomPropertiesConditionInput
  ) {
    updateShipmentCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteShipmentCustomProperties = /* GraphQL */ `
  mutation DeleteShipmentCustomProperties(
    $input: DeleteShipmentCustomPropertiesInput!
    $condition: ModelShipmentCustomPropertiesConditionInput
  ) {
    deleteShipmentCustomProperties(input: $input, condition: $condition) {
      id
      customPropertyId
      CustomProperties {
        id
        name
        searchName
        type
        propertyOf
        isRequired
        showInApp
        totalInShipment
        useAsWeight
        modifiedBy
        isDeleted
        isDefault
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        PropertyDropdownHistory {
          nextToken
          __typename
        }
        Processed {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      customPropertyValue
      shipmentId
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      requiredProperty
      propertyDropdownId
      PropertyDropdown {
        id
        name
        options
        isDeleted
        deletedAt
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        customPropertyId
        CustomProperties {
          id
          name
          searchName
          type
          propertyOf
          isRequired
          showInApp
          totalInShipment
          useAsWeight
          modifiedBy
          isDeleted
          isDefault
          deletedAt
          createdAt
          updatedAt
          organizationID
          isAvailable
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      isDeleted
      deletedAt
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createItemTypeInShipment = /* GraphQL */ `
  mutation CreateItemTypeInShipment(
    $input: CreateItemTypeInShipmentInput!
    $condition: ModelItemTypeInShipmentConditionInput
  ) {
    createItemTypeInShipment(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      shipmentId
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      quantity
      isDeleted
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      projectsItemTypeInShipmentId
      __typename
    }
  }
`;
export const updateItemTypeInShipment = /* GraphQL */ `
  mutation UpdateItemTypeInShipment(
    $input: UpdateItemTypeInShipmentInput!
    $condition: ModelItemTypeInShipmentConditionInput
  ) {
    updateItemTypeInShipment(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      shipmentId
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      quantity
      isDeleted
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      projectsItemTypeInShipmentId
      __typename
    }
  }
`;
export const deleteItemTypeInShipment = /* GraphQL */ `
  mutation DeleteItemTypeInShipment(
    $input: DeleteItemTypeInShipmentInput!
    $condition: ModelItemTypeInShipmentConditionInput
  ) {
    deleteItemTypeInShipment(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      shipmentId
      projectId
      Projects {
        id
        number
        name
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        customerId
        Customers {
          id
          name
          searchName
          organizationID
          isDeleted
          isActive
          deletedAt
          createdAt
          updatedAt
          isAvailable
          __typename
        }
        isItemLimited
        isCompleted
        inStock
        completedAt
        isDeleted
        createdAt
        updatedAt
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        deletedAt
        ItemTypes {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      Shipments {
        id
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        name
        searchNumber
        startWith
        number
        scheduleDate
        shippedDate
        notes
        isShipped
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        isDeleted
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        maximumWeight
        createdAt
        updatedAt
        deletedAt
        ItemTypeInShipment {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      quantity
      isDeleted
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      ModifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      isAvailable
      projectsItemTypeInShipmentId
      __typename
    }
  }
`;
export const createMigrationError = /* GraphQL */ `
  mutation CreateMigrationError(
    $input: CreateMigrationErrorInput!
    $condition: ModelMigrationErrorConditionInput
  ) {
    createMigrationError(input: $input, condition: $condition) {
      id
      organization
      type
      user
      error
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const updateMigrationError = /* GraphQL */ `
  mutation UpdateMigrationError(
    $input: UpdateMigrationErrorInput!
    $condition: ModelMigrationErrorConditionInput
  ) {
    updateMigrationError(input: $input, condition: $condition) {
      id
      organization
      type
      user
      error
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const deleteMigrationError = /* GraphQL */ `
  mutation DeleteMigrationError(
    $input: DeleteMigrationErrorInput!
    $condition: ModelMigrationErrorConditionInput
  ) {
    deleteMigrationError(input: $input, condition: $condition) {
      id
      organization
      type
      user
      error
      createdAt
      updatedAt
      isAvailable
      __typename
    }
  }
`;
export const createDocuments = /* GraphQL */ `
  mutation CreateDocuments(
    $input: CreateDocumentsInput!
    $condition: ModelDocumentsConditionInput
  ) {
    createDocuments(input: $input, condition: $condition) {
      id
      parentId
      name
      fileName
      fileType
      isDeleted
      sequence
      type
      extension
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      isFromMobile
      __typename
    }
  }
`;
export const updateDocuments = /* GraphQL */ `
  mutation UpdateDocuments(
    $input: UpdateDocumentsInput!
    $condition: ModelDocumentsConditionInput
  ) {
    updateDocuments(input: $input, condition: $condition) {
      id
      parentId
      name
      fileName
      fileType
      isDeleted
      sequence
      type
      extension
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      isFromMobile
      __typename
    }
  }
`;
export const deleteDocuments = /* GraphQL */ `
  mutation DeleteDocuments(
    $input: DeleteDocumentsInput!
    $condition: ModelDocumentsConditionInput
  ) {
    deleteDocuments(input: $input, condition: $condition) {
      id
      parentId
      name
      fileName
      fileType
      isDeleted
      sequence
      type
      extension
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      deletedAt
      createdAt
      updatedAt
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      DocumentAttachments {
        items {
          id
          documentId
          organizationID
          projectId
          itemsId
          itemTypeId
          associatedAt
          isDeleted
          isDefault
          type
          modifiedBy
          version
          isAvailable
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isAvailable
      isFromMobile
      __typename
    }
  }
`;
export const createDocumentAttachments = /* GraphQL */ `
  mutation CreateDocumentAttachments(
    $input: CreateDocumentAttachmentsInput!
    $condition: ModelDocumentAttachmentsConditionInput
  ) {
    createDocumentAttachments(input: $input, condition: $condition) {
      id
      documentId
      document {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      projectId
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      associatedAt
      isDeleted
      isDefault
      type
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      version
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocumentAttachments = /* GraphQL */ `
  mutation UpdateDocumentAttachments(
    $input: UpdateDocumentAttachmentsInput!
    $condition: ModelDocumentAttachmentsConditionInput
  ) {
    updateDocumentAttachments(input: $input, condition: $condition) {
      id
      documentId
      document {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      projectId
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      associatedAt
      isDeleted
      isDefault
      type
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      version
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocumentAttachments = /* GraphQL */ `
  mutation DeleteDocumentAttachments(
    $input: DeleteDocumentAttachmentsInput!
    $condition: ModelDocumentAttachmentsConditionInput
  ) {
    deleteDocumentAttachments(input: $input, condition: $condition) {
      id
      documentId
      document {
        id
        parentId
        name
        fileName
        fileType
        isDeleted
        sequence
        type
        extension
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        deletedAt
        createdAt
        updatedAt
        modifiedBy
        modifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        isFromMobile
        __typename
      }
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      projectId
      itemsId
      Items {
        id
        serialNumber
        searchNumber
        itemTypeId
        ItemTypes {
          id
          number
          name
          searchName
          searchNumber
          defaultProcessId
          type
          projectId
          productSegment
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          quantityRequired
          quantityProduced
          organizationID
          modifiedBy
          isAvailable
          informationUrl
          __typename
        }
        processId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        shipmentId
        Shipments {
          id
          organizationID
          name
          searchNumber
          startWith
          number
          scheduleDate
          shippedDate
          notes
          isShipped
          projectId
          defaultProcessId
          isDeleted
          modifiedBy
          maximumWeight
          createdAt
          updatedAt
          deletedAt
          isAvailable
          __typename
        }
        currentPhaseId
        ProcessPhases {
          id
          name
          processID
          organizationID
          order
          isDeleted
          deletedAt
          createdAt
          updatedAt
          isAvailable
          processPhasesCompletedProcessStepsId
          __typename
        }
        isProcessComplete
        hasFailedSteps
        serialNumberType
        latitude
        sentToHicams
        longitude
        isDeleted
        createdAt
        updatedAt
        forneyTestPending
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        createdBy
        CreatedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        modifiedBy
        ModifiedByUser {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        deletedAt
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        __typename
      }
      itemTypeId
      ItemTypes {
        id
        number
        name
        searchName
        searchNumber
        defaultProcessId
        Processes {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processesCompletedProcessStepsId
          __typename
        }
        ProcessSnapShot {
          id
          name
          searchName
          isGlobal
          organizationID
          customPropertyId
          customPropertyStringValue
          customPropertyNumberValue
          customPropertyDateValue
          isAllStepsOnOneScreen
          isCompleteInAnyOrder
          showProcessBasedOnCustomProperty
          templateId
          isActive
          isDeleted
          deletedAt
          createdAt
          updatedAt
          modifiedBy
          isAvailable
          processSnapShotCompletedProcessStepsId
          __typename
        }
        type
        projectId
        Projects {
          id
          number
          name
          defaultProcessId
          customerId
          isItemLimited
          isCompleted
          inStock
          completedAt
          isDeleted
          createdAt
          updatedAt
          organizationID
          modifiedBy
          deletedAt
          isAvailable
          __typename
        }
        productSegment
        isActive
        isDeleted
        deletedAt
        createdAt
        updatedAt
        quantityRequired
        quantityProduced
        organizationID
        Organization {
          id
          name
          label
          description
          isEnabled
          enableShipment
          userLimit
          appTimeZone
          token
          expiryTime
          ssrsFolderName
          subscription
          createdAt
          updatedAt
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        modifiedBy
        User {
          id
          legacyUserId
          firstName
          lastName
          email
          lastLoggedIn
          isEnabled
          isDeleted
          modifiedBy
          deletedAt
          createdAt
          updatedAt
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        isAvailable
        informationUrl
        __typename
      }
      associatedAt
      isDeleted
      isDefault
      type
      modifiedBy
      modifiedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      version
      isAvailable
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInventoryResult = /* GraphQL */ `
  mutation CreateInventoryResult(
    $input: CreateInventoryResultInput!
    $condition: ModelInventoryResultConditionInput
  ) {
    createInventoryResult(input: $input, condition: $condition) {
      id
      projectId
      projectcode
      projectname
      projectdescription
      rfidcode
      inventorystatus
      inventorynumber
      inventorydate
      bucketlistid
      bucketlistname
      integrationid
      organizationID
      tag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInventoryResult = /* GraphQL */ `
  mutation UpdateInventoryResult(
    $input: UpdateInventoryResultInput!
    $condition: ModelInventoryResultConditionInput
  ) {
    updateInventoryResult(input: $input, condition: $condition) {
      id
      projectId
      projectcode
      projectname
      projectdescription
      rfidcode
      inventorystatus
      inventorynumber
      inventorydate
      bucketlistid
      bucketlistname
      integrationid
      organizationID
      tag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInventoryResult = /* GraphQL */ `
  mutation DeleteInventoryResult(
    $input: DeleteInventoryResultInput!
    $condition: ModelInventoryResultConditionInput
  ) {
    deleteInventoryResult(input: $input, condition: $condition) {
      id
      projectId
      projectcode
      projectname
      projectdescription
      rfidcode
      inventorystatus
      inventorynumber
      inventorydate
      bucketlistid
      bucketlistname
      integrationid
      organizationID
      tag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRFIDSyncTime = /* GraphQL */ `
  mutation CreateRFIDSyncTime(
    $input: CreateRFIDSyncTimeInput!
    $condition: ModelRFIDSyncTimeConditionInput
  ) {
    createRFIDSyncTime(input: $input, condition: $condition) {
      id
      RFIDGoLastSyncTime
      RFIDHubLastSyncTime
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRFIDSyncTime = /* GraphQL */ `
  mutation UpdateRFIDSyncTime(
    $input: UpdateRFIDSyncTimeInput!
    $condition: ModelRFIDSyncTimeConditionInput
  ) {
    updateRFIDSyncTime(input: $input, condition: $condition) {
      id
      RFIDGoLastSyncTime
      RFIDHubLastSyncTime
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRFIDSyncTime = /* GraphQL */ `
  mutation DeleteRFIDSyncTime(
    $input: DeleteRFIDSyncTimeInput!
    $condition: ModelRFIDSyncTimeConditionInput
  ) {
    deleteRFIDSyncTime(input: $input, condition: $condition) {
      id
      RFIDGoLastSyncTime
      RFIDHubLastSyncTime
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRfidConfiguration = /* GraphQL */ `
  mutation CreateRfidConfiguration(
    $input: CreateRfidConfigurationInput!
    $condition: ModelRfidConfigurationConditionInput
  ) {
    createRfidConfiguration(input: $input, condition: $condition) {
      id
      rfidGoURL
      rfidGoUsername
      rfidGoPassword
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRfidConfiguration = /* GraphQL */ `
  mutation UpdateRfidConfiguration(
    $input: UpdateRfidConfigurationInput!
    $condition: ModelRfidConfigurationConditionInput
  ) {
    updateRfidConfiguration(input: $input, condition: $condition) {
      id
      rfidGoURL
      rfidGoUsername
      rfidGoPassword
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRfidConfiguration = /* GraphQL */ `
  mutation DeleteRfidConfiguration(
    $input: DeleteRfidConfigurationInput!
    $condition: ModelRfidConfigurationConditionInput
  ) {
    deleteRfidConfiguration(input: $input, condition: $condition) {
      id
      rfidGoURL
      rfidGoUsername
      rfidGoPassword
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRfidBucketList = /* GraphQL */ `
  mutation CreateRfidBucketList(
    $input: CreateRfidBucketListInput!
    $condition: ModelRfidBucketListConditionInput
  ) {
    createRfidBucketList(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      integrationId
      projectId
      name
      description
      enrollmentRule
      qualifications
      bucketItems
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRfidBucketList = /* GraphQL */ `
  mutation UpdateRfidBucketList(
    $input: UpdateRfidBucketListInput!
    $condition: ModelRfidBucketListConditionInput
  ) {
    updateRfidBucketList(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      integrationId
      projectId
      name
      description
      enrollmentRule
      qualifications
      bucketItems
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRfidBucketList = /* GraphQL */ `
  mutation DeleteRfidBucketList(
    $input: DeleteRfidBucketListInput!
    $condition: ModelRfidBucketListConditionInput
  ) {
    deleteRfidBucketList(input: $input, condition: $condition) {
      id
      organizationID
      Organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      integrationId
      projectId
      name
      description
      enrollmentRule
      qualifications
      bucketItems
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRfidLogs = /* GraphQL */ `
  mutation CreateRfidLogs(
    $input: CreateRfidLogsInput!
    $condition: ModelRfidLogsConditionInput
  ) {
    createRfidLogs(input: $input, condition: $condition) {
      id
      organizationID
      action
      status
      request
      apiEndPoint
      httpMethodType
      response
      isResent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRfidLogs = /* GraphQL */ `
  mutation UpdateRfidLogs(
    $input: UpdateRfidLogsInput!
    $condition: ModelRfidLogsConditionInput
  ) {
    updateRfidLogs(input: $input, condition: $condition) {
      id
      organizationID
      action
      status
      request
      apiEndPoint
      httpMethodType
      response
      isResent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRfidLogs = /* GraphQL */ `
  mutation DeleteRfidLogs(
    $input: DeleteRfidLogsInput!
    $condition: ModelRfidLogsConditionInput
  ) {
    deleteRfidLogs(input: $input, condition: $condition) {
      id
      organizationID
      action
      status
      request
      apiEndPoint
      httpMethodType
      response
      isResent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotificationGroups = /* GraphQL */ `
  mutation CreateNotificationGroups(
    $input: CreateNotificationGroupsInput!
    $condition: ModelNotificationGroupsConditionInput
  ) {
    createNotificationGroups(input: $input, condition: $condition) {
      id
      organizationID
      name
      description
      memberIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      __typename
    }
  }
`;
export const updateNotificationGroups = /* GraphQL */ `
  mutation UpdateNotificationGroups(
    $input: UpdateNotificationGroupsInput!
    $condition: ModelNotificationGroupsConditionInput
  ) {
    updateNotificationGroups(input: $input, condition: $condition) {
      id
      organizationID
      name
      description
      memberIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      __typename
    }
  }
`;
export const deleteNotificationGroups = /* GraphQL */ `
  mutation DeleteNotificationGroups(
    $input: DeleteNotificationGroupsInput!
    $condition: ModelNotificationGroupsConditionInput
  ) {
    deleteNotificationGroups(input: $input, condition: $condition) {
      id
      organizationID
      name
      description
      memberIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      __typename
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      organizationID
      name
      isDeleted
      description
      memberIds
      groupIds
      emaildIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      NotificationsUsers {
        items {
          id
          organizationID
          isRead
          isDeleted
          isArchived
          notificationId
          userId
          isContact
          email
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      triggerType
      oneTimeDate
      repeat
      repeatType
      intervalStart
      itemTypeId
      processId
      __typename
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      organizationID
      name
      isDeleted
      description
      memberIds
      groupIds
      emaildIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      NotificationsUsers {
        items {
          id
          organizationID
          isRead
          isDeleted
          isArchived
          notificationId
          userId
          isContact
          email
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      triggerType
      oneTimeDate
      repeat
      repeatType
      intervalStart
      itemTypeId
      processId
      __typename
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      organizationID
      name
      isDeleted
      description
      memberIds
      groupIds
      emaildIds
      createdAt
      updatedAt
      createdBy
      modifiedBy
      NotificationsUsers {
        items {
          id
          organizationID
          isRead
          isDeleted
          isArchived
          notificationId
          userId
          isContact
          email
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      triggerType
      oneTimeDate
      repeat
      repeatType
      intervalStart
      itemTypeId
      processId
      __typename
    }
  }
`;
export const createNotificationsUsers = /* GraphQL */ `
  mutation CreateNotificationsUsers(
    $input: CreateNotificationsUsersInput!
    $condition: ModelNotificationsUsersConditionInput
  ) {
    createNotificationsUsers(input: $input, condition: $condition) {
      id
      organizationID
      isRead
      isDeleted
      isArchived
      notificationId
      Notifications {
        id
        organizationID
        name
        isDeleted
        description
        memberIds
        groupIds
        emaildIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        triggerType
        oneTimeDate
        repeat
        repeatType
        intervalStart
        itemTypeId
        processId
        __typename
      }
      userId
      isContact
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotificationsUsers = /* GraphQL */ `
  mutation UpdateNotificationsUsers(
    $input: UpdateNotificationsUsersInput!
    $condition: ModelNotificationsUsersConditionInput
  ) {
    updateNotificationsUsers(input: $input, condition: $condition) {
      id
      organizationID
      isRead
      isDeleted
      isArchived
      notificationId
      Notifications {
        id
        organizationID
        name
        isDeleted
        description
        memberIds
        groupIds
        emaildIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        triggerType
        oneTimeDate
        repeat
        repeatType
        intervalStart
        itemTypeId
        processId
        __typename
      }
      userId
      isContact
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotificationsUsers = /* GraphQL */ `
  mutation DeleteNotificationsUsers(
    $input: DeleteNotificationsUsersInput!
    $condition: ModelNotificationsUsersConditionInput
  ) {
    deleteNotificationsUsers(input: $input, condition: $condition) {
      id
      organizationID
      isRead
      isDeleted
      isArchived
      notificationId
      Notifications {
        id
        organizationID
        name
        isDeleted
        description
        memberIds
        groupIds
        emaildIds
        createdAt
        updatedAt
        createdBy
        modifiedBy
        NotificationsUsers {
          nextToken
          __typename
        }
        triggerType
        oneTimeDate
        repeat
        repeatType
        intervalStart
        itemTypeId
        processId
        __typename
      }
      userId
      isContact
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuicksightDashboardLogs = /* GraphQL */ `
  mutation CreateQuicksightDashboardLogs(
    $input: CreateQuicksightDashboardLogsInput!
    $condition: ModelQuicksightDashboardLogsConditionInput
  ) {
    createQuicksightDashboardLogs(input: $input, condition: $condition) {
      id
      userId
      orgName
      dashboardId
      sheetId
      sheetname
      startTime
      endTime
      duration
      username
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuicksightDashboardLogs = /* GraphQL */ `
  mutation UpdateQuicksightDashboardLogs(
    $input: UpdateQuicksightDashboardLogsInput!
    $condition: ModelQuicksightDashboardLogsConditionInput
  ) {
    updateQuicksightDashboardLogs(input: $input, condition: $condition) {
      id
      userId
      orgName
      dashboardId
      sheetId
      sheetname
      startTime
      endTime
      duration
      username
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuicksightDashboardLogs = /* GraphQL */ `
  mutation DeleteQuicksightDashboardLogs(
    $input: DeleteQuicksightDashboardLogsInput!
    $condition: ModelQuicksightDashboardLogsConditionInput
  ) {
    deleteQuicksightDashboardLogs(input: $input, condition: $condition) {
      id
      userId
      orgName
      dashboardId
      sheetId
      sheetname
      startTime
      endTime
      duration
      username
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSyncingLogs = /* GraphQL */ `
  mutation CreateSyncingLogs(
    $input: CreateSyncingLogsInput!
    $condition: ModelSyncingLogsConditionInput
  ) {
    createSyncingLogs(input: $input, condition: $condition) {
      id
      organizationID
      createdAt
      updatedAt
      userID
      deviceType
      deviceVersion
      deviceId
      syncCompletionTime
      lastSyncTime
      internetSpeed
      isCompleted
      progressPercentage
      __typename
    }
  }
`;
export const updateSyncingLogs = /* GraphQL */ `
  mutation UpdateSyncingLogs(
    $input: UpdateSyncingLogsInput!
    $condition: ModelSyncingLogsConditionInput
  ) {
    updateSyncingLogs(input: $input, condition: $condition) {
      id
      organizationID
      createdAt
      updatedAt
      userID
      deviceType
      deviceVersion
      deviceId
      syncCompletionTime
      lastSyncTime
      internetSpeed
      isCompleted
      progressPercentage
      __typename
    }
  }
`;
export const deleteSyncingLogs = /* GraphQL */ `
  mutation DeleteSyncingLogs(
    $input: DeleteSyncingLogsInput!
    $condition: ModelSyncingLogsConditionInput
  ) {
    deleteSyncingLogs(input: $input, condition: $condition) {
      id
      organizationID
      createdAt
      updatedAt
      userID
      deviceType
      deviceVersion
      deviceId
      syncCompletionTime
      lastSyncTime
      internetSpeed
      isCompleted
      progressPercentage
      __typename
    }
  }
`;
export const createRfidProjects = /* GraphQL */ `
  mutation CreateRfidProjects(
    $input: CreateRfidProjectsInput!
    $condition: ModelRfidProjectsConditionInput
  ) {
    createRfidProjects(input: $input, condition: $condition) {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRfidProjects = /* GraphQL */ `
  mutation UpdateRfidProjects(
    $input: UpdateRfidProjectsInput!
    $condition: ModelRfidProjectsConditionInput
  ) {
    updateRfidProjects(input: $input, condition: $condition) {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRfidProjects = /* GraphQL */ `
  mutation DeleteRfidProjects(
    $input: DeleteRfidProjectsInput!
    $condition: ModelRfidProjectsConditionInput
  ) {
    deleteRfidProjects(input: $input, condition: $condition) {
      id
      name
      organizationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHolidays = /* GraphQL */ `
  mutation CreateHolidays(
    $input: CreateHolidaysInput!
    $condition: ModelHolidaysConditionInput
  ) {
    createHolidays(input: $input, condition: $condition) {
      id
      title
      description
      isRepeated
      repeatType
      recurrenceRule
      startDate
      endDate
      createdAt
      updatedAt
      organizationID
      createdBy
      updatedBy
      __typename
    }
  }
`;
export const updateHolidays = /* GraphQL */ `
  mutation UpdateHolidays(
    $input: UpdateHolidaysInput!
    $condition: ModelHolidaysConditionInput
  ) {
    updateHolidays(input: $input, condition: $condition) {
      id
      title
      description
      isRepeated
      repeatType
      recurrenceRule
      startDate
      endDate
      createdAt
      updatedAt
      organizationID
      createdBy
      updatedBy
      __typename
    }
  }
`;
export const deleteHolidays = /* GraphQL */ `
  mutation DeleteHolidays(
    $input: DeleteHolidaysInput!
    $condition: ModelHolidaysConditionInput
  ) {
    deleteHolidays(input: $input, condition: $condition) {
      id
      title
      description
      isRepeated
      repeatType
      recurrenceRule
      startDate
      endDate
      createdAt
      updatedAt
      organizationID
      createdBy
      updatedBy
      __typename
    }
  }
`;
export const createScheduler = /* GraphQL */ `
  mutation CreateScheduler(
    $input: CreateSchedulerInput!
    $condition: ModelSchedulerConditionInput
  ) {
    createScheduler(input: $input, condition: $condition) {
      id
      name
      description
      number
      itemTypeId
      qunatity
      constructionDate
      shipmentTime
      curingTime
      manufacturingDays
      labourAvailabilty
      moldInventoryValue
      createdAt
      updatedAt
      shipmentDate
      organizationID
      createdBy
      updatedBy
      SchedulerItems {
        items {
          id
          SchedulerId
          manufacturingDate
          manufactureCount
          createdAt
          updatedAt
          organizationID
          __typename
        }
        nextToken
        __typename
      }
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateScheduler = /* GraphQL */ `
  mutation UpdateScheduler(
    $input: UpdateSchedulerInput!
    $condition: ModelSchedulerConditionInput
  ) {
    updateScheduler(input: $input, condition: $condition) {
      id
      name
      description
      number
      itemTypeId
      qunatity
      constructionDate
      shipmentTime
      curingTime
      manufacturingDays
      labourAvailabilty
      moldInventoryValue
      createdAt
      updatedAt
      shipmentDate
      organizationID
      createdBy
      updatedBy
      SchedulerItems {
        items {
          id
          SchedulerId
          manufacturingDate
          manufactureCount
          createdAt
          updatedAt
          organizationID
          __typename
        }
        nextToken
        __typename
      }
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteScheduler = /* GraphQL */ `
  mutation DeleteScheduler(
    $input: DeleteSchedulerInput!
    $condition: ModelSchedulerConditionInput
  ) {
    deleteScheduler(input: $input, condition: $condition) {
      id
      name
      description
      number
      itemTypeId
      qunatity
      constructionDate
      shipmentTime
      curingTime
      manufacturingDays
      labourAvailabilty
      moldInventoryValue
      createdAt
      updatedAt
      shipmentDate
      organizationID
      createdBy
      updatedBy
      SchedulerItems {
        items {
          id
          SchedulerId
          manufacturingDate
          manufactureCount
          createdAt
          updatedAt
          organizationID
          __typename
        }
        nextToken
        __typename
      }
      CreatedByUser {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createSchedulerItems = /* GraphQL */ `
  mutation CreateSchedulerItems(
    $input: CreateSchedulerItemsInput!
    $condition: ModelSchedulerItemsConditionInput
  ) {
    createSchedulerItems(input: $input, condition: $condition) {
      id
      SchedulerId
      manufacturingDate
      manufactureCount
      createdAt
      updatedAt
      organizationID
      __typename
    }
  }
`;
export const updateSchedulerItems = /* GraphQL */ `
  mutation UpdateSchedulerItems(
    $input: UpdateSchedulerItemsInput!
    $condition: ModelSchedulerItemsConditionInput
  ) {
    updateSchedulerItems(input: $input, condition: $condition) {
      id
      SchedulerId
      manufacturingDate
      manufactureCount
      createdAt
      updatedAt
      organizationID
      __typename
    }
  }
`;
export const deleteSchedulerItems = /* GraphQL */ `
  mutation DeleteSchedulerItems(
    $input: DeleteSchedulerItemsInput!
    $condition: ModelSchedulerItemsConditionInput
  ) {
    deleteSchedulerItems(input: $input, condition: $condition) {
      id
      SchedulerId
      manufacturingDate
      manufactureCount
      createdAt
      updatedAt
      organizationID
      __typename
    }
  }
`;
export const createOrganizationUsers = /* GraphQL */ `
  mutation CreateOrganizationUsers(
    $input: CreateOrganizationUsersInput!
    $condition: ModelOrganizationUsersConditionInput
  ) {
    createOrganizationUsers(input: $input, condition: $condition) {
      id
      userId
      organizationId
      user {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganizationUsers = /* GraphQL */ `
  mutation UpdateOrganizationUsers(
    $input: UpdateOrganizationUsersInput!
    $condition: ModelOrganizationUsersConditionInput
  ) {
    updateOrganizationUsers(input: $input, condition: $condition) {
      id
      userId
      organizationId
      user {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganizationUsers = /* GraphQL */ `
  mutation DeleteOrganizationUsers(
    $input: DeleteOrganizationUsersInput!
    $condition: ModelOrganizationUsersConditionInput
  ) {
    deleteOrganizationUsers(input: $input, condition: $condition) {
      id
      userId
      organizationId
      user {
        id
        legacyUserId
        firstName
        lastName
        email
        Organization {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        lastLoggedIn
        isEnabled
        isDeleted
        modifiedBy
        deletedAt
        createdAt
        updatedAt
        Items {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        Scheduler {
          nextToken
          __typename
        }
        __typename
      }
      organization {
        id
        name
        label
        description
        Users {
          nextToken
          __typename
        }
        organizationUserRole {
          nextToken
          __typename
        }
        isEnabled
        enableShipment
        userLimit
        appTimeZone
        token
        expiryTime
        ssrsFolderName
        subscription
        createdAt
        updatedAt
        CustomProperties {
          nextToken
          __typename
        }
        Processes {
          nextToken
          __typename
        }
        ProcessSnapShot {
          nextToken
          __typename
        }
        ProcessPhases {
          nextToken
          __typename
        }
        ProcessSteps {
          nextToken
          __typename
        }
        ItemTypes {
          nextToken
          __typename
        }
        Items {
          nextToken
          __typename
        }
        ItemsCustomProperties {
          nextToken
          __typename
        }
        ItemTypeCustomProperties {
          nextToken
          __typename
        }
        Projects {
          nextToken
          __typename
        }
        ProjectCustomProperties {
          nextToken
          __typename
        }
        Customers {
          nextToken
          __typename
        }
        ItemAttachments {
          nextToken
          __typename
        }
        CompletedProcessSteps {
          nextToken
          __typename
        }
        PropertyDropdown {
          nextToken
          __typename
        }
        ItemProcesses {
          nextToken
          __typename
        }
        ForneyVaultConfigurations {
          nextToken
          __typename
        }
        ForneyVaultSetting {
          nextToken
          __typename
        }
        ForneyVaultLogs {
          nextToken
          __typename
        }
        ForneyVaultTestResults {
          nextToken
          __typename
        }
        OrganizationsIntegrations {
          nextToken
          __typename
        }
        HiCamsLogs {
          nextToken
          __typename
        }
        HiCamsSettings {
          nextToken
          __typename
        }
        Shipments {
          nextToken
          __typename
        }
        ShipmentCustomProperties {
          nextToken
          __typename
        }
        ItemTypeInShipment {
          nextToken
          __typename
        }
        HiCamsConfigurations {
          nextToken
          __typename
        }
        Documents {
          nextToken
          __typename
        }
        DocumentAttachments {
          nextToken
          __typename
        }
        RfidBucketList {
          nextToken
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
