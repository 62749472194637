import {
  ExecuteQueryLambda,
  ExecuteMutationLambda,
  ExecuteQueryLambdaFront,
} from "./Api";
export const listOrganization = (authToken) =>
  ExecuteQueryLambda(
    "listOrganizationsCustom",
    {},
    undefined,
    undefined,
    undefined,
    authToken,
    999
  );

export const createOrganization = (inputData, authToken) => {
  // return ExecuteMutation("createOrganization", inputData);
  return ExecuteMutationLambda(
    "createOrganization",
    inputData,
    undefined,
    authToken
  );
};

export const getUsersOrganization = (userID, authToken) =>
  ExecuteQueryLambda(
    "userRoleByUserIdCustom",
    { userID },
    undefined,
    undefined,
    undefined,
    authToken
  );
export const listUserOfOrganization = (organizationID, authToken) =>
  ExecuteQueryLambda(
    "organizationUserRolesByOrganizationIDAndUserID",
    {
      organizationID,
    },
    undefined,
    undefined,
    undefined,
    authToken
  );

export const updateUsers = (inputData, authToken) => {
  return ExecuteMutationLambda("updateUser", inputData, undefined, authToken);
};

export const updateUsersRole = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateOrganizationUserRole",
    inputData,
    undefined,
    authToken
  );
};

//Custom Properties

export const listUserOfOrganizationCustomProperties = (
  organizationID,
  filter,
  authToken,
  limit = 999
) =>
  ExecuteQueryLambda(
    "customPropertiesByOrganizationIDCustom",
    {
      organizationID,
    },
    undefined,
    filter,
    undefined,
    authToken,
    limit
  );

export const listUserOfOrganizationCustomPropertiesDropDown = (
  customPropertyId,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "propertyDropdownsByCustomPropertyId",
    {
      customPropertyId,
    },
    undefined,
    filter,
    undefined,
    authToken
  );
export const createCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const updateCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const createCustomPropertiesDropDown = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createPropertyDropdown",
    inputData,
    undefined,
    authToken
  );
};
export const updateCustomPropertiesDropDown = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updatePropertyDropdown",
    inputData,
    undefined,
    authToken
  );
};
export const createCustomPropertiesHistory = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createPropertyDropdownHistory",
    inputData,
    undefined,
    authToken
  );
};

//Process Muatations
export const createProcess = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createProcesses",
    inputData,
    undefined,
    authToken
  );
};
export const updateProcesses = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateProcesses",
    inputData,
    undefined,
    authToken
  );
};
export const listOfOrganizationProcess = (
  filter,
  sort,
  limit,
  nextToken,
  authToken
) =>
  ExecuteQueryLambdaFront(
    "searchProcessesCustom",
    filter,
    sort,
    limit,
    nextToken,
    authToken
  );

//Phase for process
export const createProcessPhases = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createProcessPhases",
    inputData,
    undefined,
    authToken
  );
};
export const updateProcessPhases = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateProcessPhases",
    inputData,
    undefined,
    authToken
  );
};
export const processPhasesByProcessID = (processID, filter, authToken) => {
  return ExecuteQueryLambda(
    "processPhasesByProcessIDCustom",
    {
      processID,
    },
    undefined,
    filter,
    undefined,
    authToken
  );
};

//Processes Phase Steps
export const createProcessSteps = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createProcessSteps",
    inputData,
    undefined,
    authToken
  );
};

// export const deleteProcessSteps = (adts) => {
//   return ExecuteMutationLambda("updateProcessSteps", data);
// };

export const updateProcessSteps = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateProcessSteps",
    inputData,
    undefined,
    authToken
  );
};

export const listTimeZones = (authToken) =>
  ExecuteQueryLambda(
    "listTimeZones",
    {},
    undefined,
    undefined,
    undefined,
    authToken
  );

//ItemTypesMuatatyions
export const createItemTypes = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createItemTypes",
    inputData,
    undefined,
    authToken
  );
};

export const updateItemTypes = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateItemTypes",
    inputData,
    undefined,
    authToken
  );
};

export const itemTypesByProjectId = (
  projectId,
  filter,
  nextToken,
  limit,
  authToken
) =>
  ExecuteQueryLambda(
    "itemTypesByProjectIdCustoms",
    { projectId },
    undefined,
    filter,
    nextToken,
    authToken,
    limit
  );

export const createItemTypeCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createItemTypeCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const updateItemTypeCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateItemTypeCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const itemTypeCustomPropertiesByItemTypeId = (
  itemTypeId,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "itemTypeCustomPropertiesByItemTypeId",
    { itemTypeId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const shipmentCustomPropertiesByShipmentId = (
  shipmentId,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "shipmentCustomPropertiesByShipmentId",
    { shipmentId },
    undefined,
    filter,
    undefined,
    authToken
  );

//Customer module mutations
export const customersByOrganizationID = (organizationID, filter, authToken) =>
  ExecuteQueryLambda(
    "customersByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken,
    999
  );

export const createCustomers = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createCustomers",
    inputData,
    undefined,
    authToken
  );
};
export const updateCustomers = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateCustomers",
    inputData,
    undefined,
    authToken
  );
};

//Items API Integration
export const createItems = (inputData, authToken) => {
  return ExecuteMutationLambda("createItems", inputData, undefined, authToken);
};

export const updateItems = (inputData, authToken) => {
  return ExecuteMutationLambda("updateItems", inputData, undefined, authToken);
};

export const createItemsCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createItemsCustomProperties",
    inputData,
    undefined,
    authToken
  );
};

export const createShipmentCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createShipmentCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const updateItemsCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateItemsCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const updateShipmentCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateShipmentCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const itemTypeCustomPropertiesByCustomPropertyId = (
  customPropertyId,
  authToken
) =>
  ExecuteQueryLambda(
    "itemTypeCustomPropertiesByCustomPropertyId",
    { customPropertyId },
    undefined,
    undefined,
    undefined,
    authToken
  );
export const itemsByOrganizationID = (
  organizationID,
  filter,
  authToken,
  limit = 999
) =>
  ExecuteQueryLambda(
    "itemsByOrganizationIDCustom",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken,
    limit
  );

export const itemsCustomPropertiesByItemsId = (itemsId, filter, authToken) =>
  ExecuteQueryLambda(
    "itemsCustomPropertiesByItemsId",
    { itemsId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const createItemAttachments = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createItemAttachments",
    inputData,
    undefined,
    authToken
  );
};

export const updateItemAttachments = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateItemAttachments",
    inputData,
    undefined,
    authToken
  );
};

//ProjectMutations
export const createProjects = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createProjects",
    inputData,
    undefined,
    authToken
  );
};
export const updateProjects = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateProjects",
    inputData,
    undefined,
    authToken
  );
};
export const createProjectCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createProjectCustomProperties",
    inputData,
    undefined,
    authToken
  );
};
export const updateProjectCustomProperties = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateProjectCustomProperties",
    inputData,
    undefined,
    authToken
  );
};

export const projectCustomPropertiesByProjectId = (
  projectId,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "projectCustomPropertiesByProjectId",
    { projectId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const listRoleBaseModuleAccesses = (filter, authToken) =>
  ExecuteQueryLambda(
    "listRoleBaseModuleAccessesCustom",
    {},
    undefined,
    filter,
    undefined,
    authToken
  );

export const updateRoleBaseModuleAccess = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateRoleBaseModuleAccess",
    inputData,
    undefined,
    authToken
  );
};

export const listModules = (filter, authToken) =>
  ExecuteQueryLambda(
    "listModules",
    {},
    undefined,
    filter,
    undefined,
    authToken
  );

export const createOrganizationsIntegrations = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createOrganizationsIntegrations",
    inputData,
    undefined,
    authToken
  );
};

export const updateOrganizationsIntegrations = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateOrganizationsIntegrations",
    inputData,
    undefined,
    authToken
  );
};

export const listOrganizationsIntegrations = (filter, authToken) =>
  ExecuteQueryLambda(
    "listOrganizationsIntegrations",
    {},
    undefined,
    filter,
    undefined,
    authToken
  );

//ShipmentsModule Changes
export const createShipments = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createShipments",
    inputData,
    undefined,
    authToken
  );
};
export const updateShipments = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateShipments",
    inputData,
    undefined,
    authToken
  );
};
export const shipmentsByOrganizationID = (organizationID, filter, authToken) =>
  ExecuteQueryLambda(
    "shipmentsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken
  );
export const shipmentsByProjectId = (projectId, filter, authToken) =>
  ExecuteQueryLambda(
    "shipmentsByProjectId",
    { projectId },
    undefined,
    filter,
    undefined,
    authToken
  );
export const searchShipments = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchShipments",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );
export const createItemTypeInShipment = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createItemTypeInShipment",
    inputData,
    undefined,
    authToken
  );
};
export const updateItemTypeInShipment = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateItemTypeInShipment",
    inputData,
    undefined,
    authToken
  );
};

export const itemTypeInShipmentsByShipmentIdCustom = (
  shipmentId,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "itemTypeInShipmentsByShipmentIdCustom",
    { shipmentId },
    undefined,
    filter,
    undefined,
    authToken
  );

//Fourneyvalut setting
export const createForneyVaultConfigurations = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createForneyVaultConfigurations",
    inputData,
    undefined,
    authToken
  );
};
export const forneyVaultConfigurationsByOrganizationID = (
  organizationID,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "forneyVaultConfigurationsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken
  );

export const forneyVaultSettingsByOrganizationID = (
  organizationID,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "forneyVaultSettingsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken
  );
export const searchForneyVaultTestResults = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchForneyVaultTestResults",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const searchForneyVaultLogs = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchForneyVaultLogs",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );
export const updateForneyVaultConfigurations = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateForneyVaultConfigurations",
    inputData,
    undefined,
    authToken
  );
};
export const createForneyVaultSetting = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createForneyVaultSetting",
    inputData,
    undefined,
    authToken
  );
};
export const updateForneyVaultSetting = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateForneyVaultSetting",
    inputData,
    undefined,
    authToken
  );
};

export const deleteForneyVaultSetting = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "deleteForneyVaultSetting",
    inputData,
    undefined,
    authToken
  );
};

//Additional Process itemProcessesByItemsIdCustom
export const itemProcessesByItemsIdCustom = (itemsId, filter, authToken) =>
  ExecuteQueryLambda(
    "itemProcessesByItemsIdCustom",
    { itemsId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const customItemById = (filter, authToken, itemsId) =>
  ExecuteQueryLambda(
    "searchItemsCustom",
    { itemsId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const customItemTypeById = (filter, authToken, itemsId) =>
  ExecuteQueryLambda(
    "searchItemTypesCustom",
    { itemsId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const customProjectById = (filter, authToken, itemsId) =>
  ExecuteQueryLambda(
    "searchProjectsCustom",
    { itemsId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const customShipmentsById = (filter, authToken, itemsId) =>
  ExecuteQueryLambda(
    "searchShipmentsCustom",
    { itemsId },
    undefined,
    filter,
    undefined,
    authToken
  );

export const updateOrganization = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateOrganization",
    inputData,
    undefined,
    authToken
  );
};

export const updateHiCamsSettings = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "updateHiCamsSettings",
    inputData,
    undefined,
    authToken
  );
};
export const createHiCamsSettings = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createHiCamsSettings",
    inputData,
    undefined,
    authToken
  );
};

export const createHiCamsConfigurations = (inputData, authToken) => {
  return ExecuteMutationLambda(
    "createHiCamsConfigurations",
    inputData,
    undefined,
    authToken
  );
};

export const hiCamsSettingsByOrganizationID = (
  organizationID,
  filter,
  authToken,
  limit = 999
) =>
  ExecuteQueryLambda(
    "hiCamsSettingsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken,
    999
  );

export const hiCamsLogsByOrganizationID = (
  organizationID,
  filter,
  authToken,
  limit = 999
) =>
  ExecuteQueryLambda(
    "hiCamsLogsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken,
    999
  );

export const hiCamsConfigurationsByOrganizationID = (
  organizationID,
  filter,
  authToken,
  limit = 999
) =>
  ExecuteQueryLambda(
    "hiCamsConfigurationsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken,
    999
  );

export const searchHiCamsLogs = (
  filter,
  sort,
  limit = 999,
  nextToken,
  authToken
) =>
  ExecuteQueryLambdaFront(
    "searchHiCamsLogs",
    filter,
    sort,
    limit,
    nextToken,
    authToken
  );

export const itemsByItemTypeId = (itemTypeId, filter, authToken, limit = 999) =>
  ExecuteQueryLambda(
    "itemsByItemTypeIdCustom",
    { itemTypeId },
    undefined,
    filter,
    undefined,
    authToken,
    limit
  );

export const itemsByProjectId = (projectId, filter, authToken, limit = 999) =>
  ExecuteQueryLambda(
    "itemsByProjectId",
    { projectId },
    undefined,
    filter,
    undefined,
    authToken,
    limit
  );

export const itemTypeInShipmentsByOrganizationID = (
  organizationID,
  filter,
  authToken
) =>
  ExecuteQueryLambda(
    "itemTypeInShipmentsByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken
  );

export const itemTypeInShipmentsByProjectId = (projectId, filter, authToken) =>
  ExecuteQueryLambda(
    "itemTypeInShipmentsByProjectId",
    { projectId },
    undefined,
    filter,
    undefined,
    authToken,
    999
  );

export const documentsByOrganizationIDCustom = (
  organizationID,
  sortDirection,
  filter,
  authToken,
  limit = 9999
) =>
  ExecuteQueryLambda(
    "documentsByOrganizationIDCustom",
    { organizationID },
    sortDirection,
    filter,
    limit,
    authToken
  );

export const documentAttachmentsByDocumentId = (documentId, authToken) =>
  ExecuteQueryLambda(
    "documentAttachmentsByDocumentId",
    { documentId },
    undefined,
    undefined,
    undefined,
    authToken
  );

export const createDocuments = (input, authToken) =>
  ExecuteMutationLambda("createDocuments", input, undefined, authToken);

export const updateDocuments = (input, authToken) =>
  ExecuteMutationLambda("updateDocuments", input, undefined, authToken);

export const createDocumentAttachments = (input, authToken) =>
  ExecuteMutationLambda(
    "createDocumentAttachments",
    input,
    undefined,
    authToken
  );

export const updateDocumentAttachments = (input, authToken) =>
  ExecuteMutationLambda(
    "updateDocumentAttachments",
    input,
    undefined,
    authToken
  );

export const deleteDocuments = (input, authToken) =>
  ExecuteMutationLambda("deleteDocuments", input, undefined, authToken);

export const searchCustomPropertiesCustom = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchCustomPropertiesCustom",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const deleteDocumentAttachments = (input, authToken) =>
  ExecuteMutationLambda(
    "deleteDocumentAttachments",
    input,
    undefined,
    authToken
  );

export const searchDocumentsCustom = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchDocumentsCustom",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const documentsByItemsIDCustom = (itemsId, authToken) =>
  ExecuteQueryLambda(
    "documentAttachmentsbyItemsidCustom",
    { itemsId },
    undefined,
    undefined,
    undefined,
    authToken
  );

export const createNotifications = (input, authToken) =>
  ExecuteMutationLambda("createNotifications", input, undefined, authToken);

export const createNotificationGroups = (input, authToken) =>
  ExecuteMutationLambda(
    "createNotificationGroups",
    input,
    undefined,
    authToken
  );

export const searchItems = (filter, authToken) =>
  ExecuteQueryLambdaFront(
    "searchItems",
    filter,
    undefined,
    999,
    undefined,
    authToken
  );

export const createNotificationsUsers = (input, authToken) =>
  ExecuteMutationLambda(
    "createNotificationsUsers",
    input,
    undefined,
    authToken
  );

export const searchItemTypes = (filter, authToken) =>
  ExecuteQueryLambdaFront(
    "searchItemTypes",
    filter,
    undefined,
    999,
    undefined,
    authToken
  );
export const searchProjects = (filter, authToken) =>
  ExecuteQueryLambdaFront(
    "searchProjects",
    filter,
    undefined,
    999,
    undefined,
    authToken
  );

export const deleteNotificationGroups = (input, authToken) =>
  ExecuteMutationLambda(
    "deleteNotificationGroups",
    input,
    undefined,
    authToken
  );

export const deleteNotifications = (input, authToken) =>
  ExecuteMutationLambda("deleteNotifications", input, undefined, authToken);

export const deleteNotificationsUsers = (input, authToken) =>
  ExecuteMutationLambda(
    "deleteNotificationsUsers",
    input,
    undefined,
    authToken
  );

export const updateNotificationGroups = (input, authToken) =>
  ExecuteMutationLambda(
    "updateNotificationGroups",
    input,
    undefined,
    authToken
  );

export const updateNotifications = (input, authToken) =>
  ExecuteMutationLambda("updateNotifications", input, undefined, authToken);

export const updateNotificationsUsers = (input, authToken) =>
  ExecuteMutationLambda(
    "updateNotificationsUsers",
    input,
    undefined,
    authToken
  );

export const notificationsByOrganizationID = (
  organizationID,
  authToken,
  filter
) =>
  ExecuteQueryLambda(
    "notificationsByOrganizationID",
    { organizationID },
    filter,
    undefined,
    undefined,
    authToken
  );

export const notificationGroupsByOrganizationID = (
  organizationID,
  authToken,
  filter
) =>
  ExecuteQueryLambda(
    "notificationGroupsByOrganizationID",
    { organizationID },
    filter,
    undefined,
    undefined,
    authToken
  );

export const notificationsUsersByOrganizationID = (
  organizationID,
  authToken,
  filter
) =>
  ExecuteQueryLambda(
    "notificationsUsersByOrganizationID",
    { organizationID },
    undefined,
    filter,
    undefined,
    authToken
  );

export const searchNotificationsUsers = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchNotificationsUsers",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const searchNotifications = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchNotifications",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const searchNotificationGroups = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchNotificationGroups",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const searchRfidBucketList = (
  filter,
  sort,
  limit = 999,
  nextToken,
  authToken
) =>
  ExecuteQueryLambdaFront(
    "searchRfidBucketLists",
    filter,
    sort,
    limit,
    nextToken,
    authToken
  );

export const createRfidBucketList = (input, authToken) =>
  ExecuteMutationLambda("createRfidBucketList", input, undefined, authToken);
export const updateRfidBucketList = (input, authToken) =>
  ExecuteMutationLambda("updateRfidBucketList", input, undefined, authToken);
export const deleteRfidBucketList = (input, authToken) =>
  ExecuteMutationLambda("deleteRfidBucketList", input, undefined, authToken);
export const searchRfidLogs = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "searchRfidLogs",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const getRfidBucketList = (id, currentIdToken) =>
  ExecuteQueryLambdaFront("getRfidBucketList", id, currentIdToken);

export const documentAttachmentsByItemTypeId = (itemTypeId, authToken) =>
  ExecuteQueryLambda(
    "documentAttachmentsByItemTypeId",
    { itemTypeId },
    undefined,
    undefined,
    undefined,
    authToken
  );

export const listRfidProjects = (filter, currentIdToken) =>
  ExecuteQueryLambdaFront(
    "listRfidProjects",
    filter,
    undefined,
    999,
    undefined,
    currentIdToken
  );

export const listRfidBucketLists = (
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) =>
  ExecuteQueryLambdaFront(
    "listRfidBucketLists",
    filter,
    sort,
    limit,
    nextToken,
    currentIdToken
  );

export const listOrganizationUserRoles = (filter, currentIdToken) =>
  ExecuteQueryLambdaFront(
    "listOrganizationUserRoles",
    filter,
    undefined,
    999,
    undefined,
    currentIdToken
  );
