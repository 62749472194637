import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Drawer,
  Row,
  Typography,
  Col,
  Grid,
  Space,
  Tag,
  Checkbox,
  message,
} from "antd";
import {
  DeleteOutlined,
  DoubleRightOutlined,
  EditOutlined,
  MedicineBoxOutlined,
} from "@ant-design/icons";
import { NotificationTab } from "utils/Constants";
import SearchableTableWithSorting from "Common/Components/DataTable/DataTableWithSorting";
import DeleteNotificationModal from "../DeleteNotifications/DeleteNotification";
import { dateTimeConversionBasedOnTimeZone } from "utils/Helper/commonMethods";
import CreateNotification from "../ManageNotifications/CreateNotification";
import CreateNotificationGroup from "../ManageNotifications/CreateGroup";
import { getMember } from "utils/RESTApi";
import { getCurrentIdToken, getOrganizationId } from "store/slices/loginSlice";
import { useSelector } from "react-redux";
import { CommonError } from "utils/Helper/CommonError";
import DeleteModal from "Common/Components/ReusebaleModals/DeleteModal";
import { handleDelete } from "../Common";
import {
  listUserOfOrganization,
  notificationGroupsByOrganizationID,
  searchNotifications,
  updateNotificationsUsers,
} from "utils/Actions";
export default function NotificationDrawer({
  open,
  onClose,
  notificationData,
  hasReadAccess,
  hasUpdateAccess,
  hasDeleteAccess,
  isadmin,
  loading,
  activeTab,
  viewType,
  userId,
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [isFullScreen, setFullScreen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(706);
  const [notificationsToDelete, setNotificationsToDelete] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [createNotificationConfigVisible, setCreateNotificationConfigVisible] =
    useState(false);
  const [notificaionConfigEdit, setNotificaionConfigEdit] = useState(false);
  const [createNotificationGroupVisible, setCreateNotificationGroupVisible] =
    useState(false);
  const [notificaionGroupEdit, setNotificaionGroupEdit] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentIdToken);
  const [deleteLodading, setDeleteLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);

  const fetchNotification = async () => {
    setLoadData(true);
    try {
      const filter = {
        organizationID: { eq: userOrganizationId },
        id: { eq: notificationData?.notificationId },
      };
      const limit = 20;
      const sort = {};

      const response = await searchNotifications(
        filter,
        sort,
        limit,
        null,
        currentIdToken
      );

      setDataToShow(response?.items[0]);
    } catch (error) {
      CommonError(error);
    } finally {
      setLoadData(false);
    }
  };

  const fetchUsers = useCallback(async () => {
    if (
      activeTab === NotificationTab.Configurationsadmin &&
      viewType === "receiverList" &&
      dataToShow?.memberIds?.length > 0
    ) {
      try {
        setLoadingUsers(true);
        const response = await listUserOfOrganization(
          userOrganizationId,
          currentIdToken
        );

        // Filter users based on memberIds
        const filteredUsers = response.items
          .filter((item) => dataToShow.memberIds.includes(item.User.id))
          .map((item) => ({
            key: item.User.id,
            name: `${item.User.firstName} ${item.User.lastName}`,
            email: item.User.email,
          }));

        setUserList(filteredUsers);
      } catch (error) {
        message.error("Failed to load users");
      } finally {
        setLoadingUsers(false);
      }
    }
  }, [activeTab, viewType, dataToShow, userOrganizationId, currentIdToken]);

  const updateIsRead = async () => {
    const memberId = dataToShow?.memberIds?.filter((id) => id === userId);
    // Check if drawer is open, notification exists, and we're in the correct tabs
    if (
      open &&
      dataToShow &&
      !dataToShow.isRead &&
      (activeTab === NotificationTab.Inbox ||
        activeTab === NotificationTab.Archivelist)
    ) {
      try {
        const input = {
          id: notificationData.id,
          organizationID: notificationData.organizationID,
          isRead: true,
          notificationId: notificationData.notificationId,
          userId: notificationData?.userId,
        };

        await updateNotificationsUsers(input, currentIdToken);
      } catch (error) {
        CommonError(error);
      }
    }
  };
  useEffect(() => {
    const memberId = dataToShow?.memberIds?.filter((id) => id === userId);

    if (!notificationData?.isRead && memberId?.length === 1) {
      updateIsRead();
    }
  }, [open, dataToShow, activeTab]);
  useEffect(() => {
    if (!open) {
      setFullScreen(false);
      setDrawerWidth(706);
    }
  }, [open]);

  const setFullScreenWidth = (value) => {
    if (value) {
      let width =
        document.activeElement.clientWidth > 1024
          ? document.activeElement.clientWidth - 269
          : document.activeElement.clientWidth;
      setDrawerWidth(width);
      setFullScreen(value);
    } else {
      setFullScreen(value);
      setDrawerWidth(706);
    }
  };

  //show delete modal
  const showDeleteModal = (record) => {
    setNotificationsToDelete(record ? [record] : []);
    setDeleteModalVisible(true);
  };

  const handleArchiveToggle = async (record) => {
    try {
      const input = {
        id: dataToShow?.NotificationsUsers?.items[0].id,
        organizationID: dataToShow?.NotificationsUsers?.items[0].organizationID,
        isArchived: !dataToShow?.NotificationsUsers?.items[0].isArchived,
        notificationId: dataToShow?.NotificationsUsers?.items[0].notificationId,
        userId: dataToShow?.NotificationsUsers?.items[0].userId,
      };

      await updateNotificationsUsers(input, currentIdToken);

      message.success(
        `Notification ${
          !record.isArchived ? "archived" : "unarchived"
        } successfully`
      );

      // Close drawer after successful operation
      onClose();

      // Refresh data if needed through parent component
      if (typeof setDeleteLoading === "function") {
        setDeleteLoading((prev) => !prev);
      }
    } catch (error) {
      message.error(
        `Failed to ${record.isArchived ? "unarchive" : "archive"} notification`
      );
    }
  };

  const renderDrawerTitle = () => {
    if (!dataToShow) {
      return "Preview";
    } else {
      return dataToShow?.name;
    }
  };

  let columnMember = [
    {
      title: "Member Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, value) => (
        <Typography.Text strong>
          {value.firstName + " " + value.lastName}
        </Typography.Text>
      ),
      width: 200,
    },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      render: (text, value) => <Typography.Text>{value.email}</Typography.Text>,
      // width: 10,
    },
  ];
  const fetchGroups = useCallback(async () => {
    if (
      activeTab === NotificationTab.Configurationsadmin &&
      viewType === "receiverList" &&
      dataToShow?.groupIds?.length > 0
    ) {
      try {
        setLoadingGroups(true);
        // Create filter based on groupIds from dataToShow
        const filter = {
          id: {
            in: dataToShow.groupIds,
          },
        };

        const response = await notificationGroupsByOrganizationID(
          userOrganizationId,
          currentIdToken,
          filter
        );

        const transformedGroups = response.items.map((group) => ({
          key: group.id,
          name: group.name,
          description: group.description,
          memberCount: group.memberIds?.length || 0,
        }));

        setGroupList(transformedGroups);
      } catch (error) {
        message.error("Failed to load groups");
      } finally {
        setLoadingGroups(false);
      }
    }
  }, [activeTab, viewType, dataToShow, userOrganizationId, currentIdToken]);
  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, [fetchUsers]);
  const fetchMembers = async () => {
    setLoadData(true);
    if (dataToShow?.id) {
      try {
        const members = await getMember(dataToShow.id); // Fetch data using getMember
        setMemberList(members?.data); // Store fetched members in state
        setLoadData(false);
      } catch (error) {
        CommonError(error);
        setLoadData(false);
      }
    }
  };
  useEffect(() => {
    if (notificationData?.notificationId) {
      fetchNotification();
    } else {
      setDataToShow(notificationData);
    }
  }, [notificationData]);
  useEffect(() => {
    setMemberList([]);
    fetchMembers();
  }, [dataToShow]);

  const groupColumns = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: "40%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: "40%",
    },
    {
      title: "Members",
      dataIndex: "memberCount",
      key: "memberCount",
      sorter: true,
      width: "20%",
      render: (count) => <Tag color="blue">{count}</Tag>,
    },
  ];
  const groupData =
    dataToShow?.receiverList?.map((group, index) => ({
      key: index,
      name: group.name,
      memberCount: group.contacts?.length || 0,
    })) || [];
  const userColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: "60%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
  ];

  const userData =
    dataToShow?.receiverList?.flatMap(
      (group) =>
        group.contacts?.map((member, index) => ({
          key: `${group.name}-${index}`,
          name: member,
        })) || []
    ) || [];

  const renderContent = () => {
    if (!dataToShow)
      return <Typography.Text>No data available</Typography.Text>;
    // For non-admin view
    <div className="notification-content mt-4 p-4 border rounded">
      <Row justify="space-between" className="mb-3">
        <Col>
          <Typography.Title className="mb-5 fs-21">
            <strong>Notification Details</strong>
          </Typography.Title>
        </Col>
        <Col>
          {(activeTab === NotificationTab.Inbox ||
            activeTab === NotificationTab.Archivelist) &&
            hasUpdateAccess && (
              <Button
                type="text"
                className="text-primary fs-14"
                onClick={() => handleArchiveToggle(dataToShow)}
              >
                <MedicineBoxOutlined />
                {notificationData?.id && notificationData?.isArchived
                  ? "Unarchive"
                  : "Archive"}
              </Button>
            )}
          {!isadmin && hasDeleteAccess && (
            <Button
              type="text"
              className="text-danger fs-14"
              onClick={() => showDeleteModal(dataToShow)}
            >
              <DeleteOutlined />
              Delete
            </Button>
          )}
        </Col>
      </Row>
      <Typography.Paragraph>
        <strong>Date Received:</strong>{" "}
        {dataToShow?.createdAt
          ? dateTimeConversionBasedOnTimeZone(
              dataToShow.createdAt,
              "YYYY-MM-DD HH:mm:ss A"
            )
          : "N/A"}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {dataToShow?.Notifications?.description ||
          dataToShow?.Notifications?.preview ||
          "No description available"}
      </Typography.Paragraph>
    </div>;
    // For admin view
    switch (activeTab) {
      case NotificationTab.Inbox:
      case NotificationTab.Archivelist:
        return (
          <div className="notification-content mt-4 p-4 border rounded">
            <Row justify="space-between" className="mb-3">
              <Col>
                <Typography.Title className="mb-5 fs-21">
                  <strong>Notification Details</strong>
                </Typography.Title>
              </Col>
              {hasDeleteAccess && (
                <Col>
                  {(activeTab === NotificationTab.Inbox ||
                    activeTab === NotificationTab.Archivelist) &&
                    hasUpdateAccess && (
                      <Button
                        type="text"
                        className="text-primary fs-14"
                        onClick={() => handleArchiveToggle(dataToShow)}
                      >
                        <MedicineBoxOutlined />
                        {notificationData?.id && notificationData?.isArchived
                          ? "Unarchive"
                          : "Archive"}
                      </Button>
                    )}
                  <Button
                    type="text"
                    className="text-danger fs-14"
                    onClick={() => showDeleteModal(dataToShow)}
                  >
                    <DeleteOutlined />
                    Delete
                  </Button>
                </Col>
              )}
            </Row>
            <Typography.Paragraph>
              <strong>Date Received:</strong>{" "}
              {dataToShow?.createdAt
                ? dateTimeConversionBasedOnTimeZone(
                    dataToShow.createdAt,
                    "YYYY-MM-DD HH:mm:ss A"
                  )
                : "N/A"}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dataToShow?.Notifications?.description ||
                dataToShow?.Notifications?.preview ||
                dataToShow?.description ||
                "No description available"}
            </Typography.Paragraph>
          </div>
        );

      case NotificationTab.Configurationsadmin:
        if (viewType === "receiverList") {
          return (
            <div>
              <Typography.Title className="fs-21">
                Notification Details
              </Typography.Title>
              {/* Groups Table */}
              <div className="mb-8">
                <Typography.Title level={5} className="mb-3">
                  Group
                </Typography.Title>
                <SearchableTableWithSorting
                  rowKey="key"
                  screens={screens}
                  columns={groupColumns}
                  data={groupList}
                  loading={loadingGroups}
                  handleChange={() => {}}
                  className="groups-table"
                />
              </div>
              {/* Users Table */}
              <div className="p-4">
                <Typography.Title level={5} className="mb-4">
                  Users ({userList.length})
                </Typography.Title>
                <SearchableTableWithSorting
                  rowKey="key"
                  screens={screens} // Pass the screens prop here
                  columns={userColumns}
                  data={userList}
                  loading={loadingUsers}
                  handleChange={() => {}}
                  className="users-table"
                />
              </div>
            </div>
          );
        }

        return (
          <div className="notification-content mt-4 p-4 border rounded">
            <Row justify="space-between" className="mb-3">
              <Col>
                <Typography.Title className="fs-21">
                  Notification Details
                </Typography.Title>
              </Col>
              <Col>
                {hasUpdateAccess && (
                  <Button
                    type="text"
                    className="text-primary fs-14"
                    onClick={() => {
                      setCreateNotificationConfigVisible(true);
                      setNotificaionConfigEdit(true);
                      setSelectedNotification(dataToShow);
                    }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                )}

                {hasDeleteAccess && (
                  <Button
                    type="text"
                    className="text-danger fs-14"
                    onClick={() => showDeleteModal(dataToShow)}
                  >
                    <DeleteOutlined />
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
            {/* <Checkbox checked className="mb-10">
              Active
            </Checkbox> */}
            <Typography.Paragraph className="mb-3">
              <strong>Created Date:</strong>{" "}
              {dataToShow?.createdAt
                ? dateTimeConversionBasedOnTimeZone(
                    dataToShow.createdAt,
                    "YYYY-MM-DD HH:mm:ss A"
                  )
                : "N/A"}
            </Typography.Paragraph>
            <Typography.Paragraph className="mb-3">
              <strong>Modified Date:</strong>{" "}
              {dataToShow?.updatedAt
                ? dateTimeConversionBasedOnTimeZone(
                    dataToShow.updatedAt,
                    "YYYY-MM-DD HH:mm:ss A"
                  )
                : "N/A"}
            </Typography.Paragraph>
            <Typography.Paragraph className="mb-3">
              {dataToShow?.description || "No description available"}
            </Typography.Paragraph>
          </div>
        );

      case NotificationTab.Groupsadmin:
        return (
          <>
            <div className="notification-content mt-4 p-4 border rounded">
              <Row justify="space-between" className="mb-3">
                <Col>
                  <Typography.Title className="fs-21">
                    Notification Details
                  </Typography.Title>
                </Col>
                <Col>
                  {hasUpdateAccess && (
                    <Button
                      type="text"
                      className="text-primary fs-14"
                      onClick={() => {
                        setCreateNotificationGroupVisible(true);
                        setNotificaionGroupEdit(true);
                        setSelectedNotification(dataToShow);
                      }}
                    >
                      <EditOutlined />
                      Edit
                    </Button>
                  )}

                  {hasDeleteAccess && (
                    <Button
                      type="text"
                      className="text-danger fs-14"
                      onClick={() => showDeleteModal(dataToShow)}
                    >
                      <DeleteOutlined />
                      Delete
                    </Button>
                  )}
                </Col>
              </Row>

              <Typography.Paragraph>
                {dataToShow?.description || "No description available"}
              </Typography.Paragraph>
              <Typography.Paragraph>
                {dataToShow?.createdAt
                  ? dateTimeConversionBasedOnTimeZone(
                      dataToShow.createdAt,
                      "YYYY-MM-DD HH:mm:ss A"
                    )
                  : "N/A"}
              </Typography.Paragraph>
            </div>
            <div className="mt-4">
              <Typography.Title level={5}>
                {" "}
                ({dataToShow?.memberIds?.length || 0}) contacts
              </Typography.Title>
              <div className="member-list">
                <SearchableTableWithSorting
                  rowKey="id"
                  screens={screens}
                  columns={columnMember}
                  data={memberList}
                  loading={loadData}
                  className="notifications-table mt-10"
                />
              </div>
            </div>
          </>
        );

      default:
        return (
          <Typography.Text>No content available for this view</Typography.Text>
        );
    }
  };

  return (
    <Drawer
      className="related-item-drawer brawer-with-btn"
      width={drawerWidth}
      title={
        <Row align="middle" justify="space-between" className="drawer-title">
          <Row align="middle">
            <Button
              type="text"
              className="text-gray fs-14 p-0"
              onClick={() => {
                setFullScreen(false);
                onClose();
              }}
            >
              <DoubleRightOutlined />
            </Button>
            <Typography.Title className="mb-0 ml-10 fs-21">
              {renderDrawerTitle()}
            </Typography.Title>
          </Row>
          <Typography.Title
            level={5}
            className="mb-0 ml-10 text-gray fw-400 fs-14 cursor-pointer text-primary"
            onClick={() => setFullScreenWidth(!isFullScreen)}
          >
            {isFullScreen ? "View in small screen" : "View in fullscreen"}
          </Typography.Title>
        </Row>
      }
      closeIcon={null}
      closable={false}
      placement={screens.xs ? "bottom" : "right"}
      onClose={onClose}
      open={open}
      destroyOnClose={true}
      footer={[]}
    >
      <div>
        <Row>
          <Col span={24}>{renderContent()}</Col>
        </Row>
      </div>
      {/* Notification Delete Modal For Conformations */}
      <DeleteModal
        isDelete={deleteModalVisible}
        header={`Delete Notification`}
        setIsDelete={setDeleteModalVisible}
        message={`Are you sure you want to delete this notification?`}
        handleSubmit={async () => {
          try {
            await handleDelete(
              dataToShow?.NotificationsUsers?.items[0]?.id,
              activeTab,
              setDeleteLoading,
              currentIdToken,
              setDeleteModalVisible
            );

            // Close drawer after successful deletion
            onClose();

            // Refresh data if needed through parent component
            if (typeof setDeleteLoading === "function") {
              setDeleteLoading((prev) => !prev);
            }
          } catch (error) {
            message.error("Failed to delete notification");
          }
        }}
        loadingdata={deleteLodading}
      />
      <CreateNotification
        open={createNotificationConfigVisible}
        onCancel={() => {
          setCreateNotificationConfigVisible(false);
          setNotificaionConfigEdit(false);
        }}
        loading={loading}
        notificationConfigDataEdit={notificaionConfigEdit}
        notificationData={selectedNotification}
      />
      <CreateNotificationGroup
        open={createNotificationGroupVisible}
        onCancel={() => {
          setCreateNotificationGroupVisible(false);
          setNotificaionGroupEdit(false);
          fetchMembers();
        }}
        loading={loading}
        notificationDataEdit={notificaionGroupEdit}
        notificationData={selectedNotification}
      />
    </Drawer>
  );
}
